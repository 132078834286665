<template>
  <div class="wrap" ref='userBarLineChartRef'>
    <!-- {{ currentSelectArea }} -->
    <a-spin :spinning="spinning" size="large">
      <div class="title-wrap"> 
        <div class="left">
          <div class="title">用户存量/增长看板</div>
          <a-select v-show="bigAreaVisible" size="small" v-model="bigAreaVal" @change="bigAreaSelectChange" :getPopupContainer='getPopupContainer' style="width: 110px;margin:5px 0 5px 10px;">
            <a-select-option v-for="(val) in bigAreaOptions" :key="val.label" :value="val.value">{{val.label}}</a-select-option>
          </a-select>
          
          <a-tree-select
            v-model="regionValue"
            style="width: 120px;margin: 5px"
            :disabled="treeSelectDisabled"
            :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
            :tree-data="regionData"
            placeholder="请选择"
            showSearch
            :getPopupContainer='getPopupContainer'
            treeNodeFilterProp='title'
            size='small'
            @select='regionSelectChange'
            :treeDefaultExpandedKeys='[0]'
            :replaceFields='replaceFields'
            searchPlaceholder='请输入'
            />
        </div>
        <div class="mid"></div>
        <div class="right">
          <div class="search">
            <a-radio-group
              size="small"
              :value="radioDefaultValue"
              button-style="solid"
              @change="onChangeRadio"
            >
              <a-radio-button value="day">
                今天
              </a-radio-button>
              <a-radio-button value="week">
                本周
              </a-radio-button>
              <a-radio-button value="month">
                本月
              </a-radio-button>
              <a-radio-button value="year">
                今年
              </a-radio-button>
            </a-radio-group>
            &nbsp;
            <a-range-picker
              size="small"
              style="width:200px;"
              :getCalendarContainer='getPopupContainer'
              :value="rangeValue"
              @change="onChangeRangePick"
            />
         </div>
        </div>
      </div>
      
      
      <div class="gmv-bar-line-chart" id="userBarLineChart"></div>
    </a-spin>
  </div>
</template>

<script>
import api from '@/api';
import _mixin from './_mixin';
import * as echarts from 'echarts';
import moment from 'moment';
export default {
  name: 'userBarLineChart',
  mixins:[_mixin],
  props: {
    user_auth: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      spinning: false,
      chart: null,
      chartBarData: [],
      chartLineData: [],
      dateTypeMap: {
        'day': 1,
        'week': 24,
        'month': 24,
        'year': 'month',
        'dateRange': 24
      },
      xLine:[],
      userBase: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    moment,
    async init() {
      if(this.user_auth && this.user_auth.level === 'district'){
        this.handleOptionsByAuth(this.user_auth, 'city')
      }else {
        this.handleOptionsByAuth(this.user_auth)
      }
      await this.fetchDataFuc()
    },
    // 获取数据
    async fetchDataFuc() {
      // 未联调
      try {
        const { startTime, endTime} = this.handleSelectParams()
        if(endTime - startTime > 86400 * 366) {
          this.$message.error('查询时间不能超过一年')
          return
        }
        const code0 = this.currentSelectArea[0].code
        const code1 = this.currentSelectArea[1].code
        const code2 = this.currentSelectArea[2].code
        const name1 = this.currentSelectArea[1].name
        const name2 = this.currentSelectArea[2].name
        let params = {
          start_time: startTime,
          end_time: endTime,
          period: this.dateTypeMap[this.dateSelectType],
          province: '',
          city: ''
        }
        // 如果选了市
        if(code2 != 0) {
          params.province = name1
          params.city = name2
        // 选了省，不选市 
        }else if (code1 != 0  &&  code2 == 0){
          params.province = name1
          params.city = ''
        // 选了大区，不选省市
        }else if (code0 && code1 == 0 && code2 == 0){
          const arr = this.areaToCityName[code0]
          params.province = arr.join()
          params.city = ''
        // 什么都不选，查全国
        } else if (code0 == 0 && code1 == 0 && code2 == 0) {
          params.province = ''
          params.city = ''
        }
        this.spinning = true;
        const { code:codeBar, data:dataBar, msg:msgBar } = await api.chartsManage.getUserStock(params);
        if (codeBar && codeBar === '00000') {
          this.chartBarData = dataBar || [];
        } else {
          this.$message.error(msgBar)
        }
        const { code: codeLine, data: dataLine, msg: msgLine} = await api.chartsManage.getUserIncrement(params);
        if (codeLine && codeLine === '00000') {
          this.chartLineData = dataLine || [];
        }
        else {
          this.$message.error(msgLine)
        }
        this.spinning = false;
        this.initChart();
      } catch (error) {
        console.log(error)
        this.spinning = false;  
      }
    },
    // 渲染图表
    initChart() {
      let chartDom = document.getElementById('userBarLineChart');
      if (chartDom) {
        setTimeout(() => {
          if(!this.chartBarData) {
            return
          }
          this.chart = echarts.init(chartDom, { renderer: 'svg' });
          this.chart.clear()
          let ops = this.getOptions()
          ops.dataZoom = this.dataZoomHandle(this.xLine.length)
          this.chart.setOption(ops);
          window.addEventListener('resize', () => {
            this.chart.clear()
            let ops = this.getOptions()
            ops.dataZoom = this.dataZoomHandle(this.xLine.length)
            this.chart.resize();
            this.chart.setOption(ops);
          });
          this.spinning = false; 
        }, 300);
      }
    },
    // 处理props数据，扁平化
    handleProps(arr, type) {
      // 0普通用户,1推广用户,2初级用户,3中级用户,4高级用户
      let user0 = []
      let user1 = []
      let user2 = []
      let user3 = []
      let user4 = []
      if (!arr) return { user0, user1, user2, user3, user4 };
      // 普通用户作为基数
      if(type == 'stock') {
        this.userBase = arr.filter(item =>  item.roleName == '普通用户' )
      } 
      this.userBase && this.userBase.map((a, i) => {
        const u0 = arr.filter(b => b.time == a.time && b.roleName == '普通用户')
        if(u0 && u0.length) {
          user0[i] = u0[0].number
        } else {
          user0[i] = 0
        }
        const u1 = arr.filter(b => b.time == a.time && b.roleName == '推广用户')
        if(u1 && u1.length) {
          user1[i] = u1[0].number
        } else {
          user1[i] = 0
        }
        const u2 = arr.filter(b => b.time == a.time && b.roleName == '初级会员')
        if(u2 && u2.length) {
          user2[i] = u2[0].number
        } else {
          user2[i] = 0
        }
        const u3 = arr.filter(b => b.time == a.time && b.roleName == '中级会员')
        if(u3 && u3.length) {
          user3[i] = u3[0].number
        } else {
          user3[i] = 0
        }
        const u4 = arr.filter(b => b.time == a.time && b.roleName == '高级会员')
        if(u4 && u4.length) {
          user4[i] = u4[0].number
        } else {
          user4[i] = 0
        }
      })
      
      return { user0, user1, user2, user3, user4 };
    },
    getPopupContainer () {
      return this.$refs.userBarLineChartRef
    },
    // 缩放百分比处理 
    dataZoomHandle(num){
      let dataZoom = {
        bottom: '10',
        show: true,
        start: 0,
        end: 100
      }
      if(num <= 31) {
        dataZoom.show = false
        dataZoom.end = 100
      }
      else if (num >31 && num < 100){
        dataZoom.end = 50
      }
      else if (num >= 100 && num < 200) {
        dataZoom.end = 30
      }
      else if(num >= 200 && num < 400) {
        dataZoom.end = 10
      } else {
        dataZoom.end = 10
      }
      return dataZoom
    },
    // 处理charts options
    getOptions() {
      const { 
        user0: bar_user0, 
        user1: bar_user1, 
        user2: bar_user2,
        user3: bar_user3, 
        user4: bar_user4 
      } = this.handleProps(this.chartBarData, 'stock');

      const { 
        user0: line_user0, 
        user1: line_user1, 
        user2: line_user2,
        user3: line_user3, 
        user4: line_user4 
      } = this.handleProps(this.chartLineData, 'increment');

      // 动态生成X轴
      const xLine = this.chartBarData.filter(item => item.roleName == '普通用户').map(item => {
        if(this.dateSelectType == 'day') {
          return moment(item.time* 1000).format('H') + '时'
        }
        if(['week','month','dateRange'].includes(this.dateSelectType)) {
          return moment(item.time* 1000).format('YYYY年MM月DD日') 
        }
        if(this.dateSelectType == 'year') {
          return moment(item.time* 1000).format('YYYY年MM月')
        }
      })
      this.xLine = xLine
      let legendData = ['普通用户存量','推广用户存量','初级会员存量','中级会员存量','高级会员存量','普通用户增长','推广用户增长','初级会员增长','中级会员增长','高级会员增长']
      let seriesData = [
        {
          name: '普通用户存量',
          type: 'bar',
          yAxisIndex: 0,
          data: bar_user0,
          itemStyle: {
            color: '#3BA272'
          }
        },
        {
          name: '推广用户存量',
          type: 'bar',
          yAxisIndex: 0,
          data: bar_user1,
          itemStyle: {
            color: '#FC8452'
          }
        },
        {
          name: '初级会员存量',
          type: 'bar',
          yAxisIndex: 0,
          data: bar_user2,
          itemStyle: {
            color: '#EE6666'
          }
        },
        {
          name: '中级会员存量',
          type: 'bar',
          yAxisIndex: 0,
          data: bar_user3,
          itemStyle: {
            color: '#FAC858'
          }
        },
        {
          name: '高级会员存量',
          type: 'bar',
          yAxisIndex: 0,
          data: bar_user4,
          itemStyle: {
            color: '#1890FF'
          }
        },
        // 折线图
        {
          name: '普通用户增长',
          type: 'line',
          yAxisIndex: 1,
          data: line_user0,
          itemStyle: {
            color: '#3BA272'
          }
        },
        {
          name: '推广用户增长',
          type: 'line',
          yAxisIndex: 1,
          data: line_user1,
          itemStyle: {
            color: '#FC8452'
          }
        },
        {
          name: '初级会员增长',
          type: 'line',
          yAxisIndex: 1,
          data: line_user2,
          itemStyle: {
            color: '#EE6666'
          }
        },
        {
          name: '中级会员增长',
          type: 'line',
          yAxisIndex: 1,
          data: line_user3,
          itemStyle: {
            color: '#FAC858'
          }
        },
        {
          name: '高级会员增长',
          type: 'line',
          yAxisIndex: 1,
          data: line_user4,
          itemStyle: {
            color: '#1890FF'
          }
        }
      ]
      const option = {
        title: {
          text: '',
          textStyle: {
            color: '#666'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            },
            label: {
              formatter: '{value}'
            },
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%'
        },
        legend: {
          data: legendData
        },
        xAxis: [
          {
            type: 'category',
            data: xLine,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        dataZoom: [
          {
            bottom: '10',
            start: 10,
            end: 20
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '存量(人)',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '增长(人)',
            alignTicks: true,
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: seriesData
      };
      return option;
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 95%;
  padding: 10px;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .title {
    font-size: 20px;
    color: #555;
  }
}
.title-wrap{
  display: flex;
  width: 100%;
  height: 35px;
  .left{
    display: flex;
  }
  .mid{
    flex: 1;
  }
}
.gmv-bar-line-chart {
  width: 100%;
  height: 430px;
  padding: 5px 10px;
}
</style>
