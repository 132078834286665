<template>
   <a-modal v-model="visible" width="750px" @cancel='onCancel' :footer="null" :title="title">
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
         :loading="loading"
         :scroll="{x: true}"
         size="default">
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />
    </a-modal>
</template>

<script>
import api from '@/api'
export default {
   name: 'SaleRankTable',
   props: {
      title: {
         type: String,
         default: '区域销售排名'
      },
      value: {
         type: Boolean,
         default: false,
      },
      params: {
         type: Object,
         default: () => ({})
      },
      funcName: {
         type: String,
         required: true
      },
      adcode: {
         type: [String, Number]
      },
      level: {
         type: Number
      },
      selectReigon: {
         type: Array,
         default: () => ([])
      }
   },
   data () {
      return {
         visible: false,
         pageNum: 1,
         total: 0,
         pageSize: 10,
         tableData: [],
         loading: false
      }
   },
   computed: {
      columns () {
         let areaName = ['省', '市', '区']
         return [
            {
               title: '排名',
               align: 'center',
               ellipsis: true,
               customRender: (text, record, index) => {
                  return this.pageSize * (this.pageNum - 1) + index + 1
               }
            },
            {
               title: areaName[this.level],
               dataIndex: 'name',
               align: 'center',
               ellipsis: true
            },
            {
               title: '销售额合计',
               dataIndex: 'amount',
               align: 'center',
               ellipsis: true
            }
         ]
      }
   },
   watch: {
      value: {
         immediate: true,
         handler (val) {
            this.pageNum = 1
            this.pageSize = 10
            this.visible = val
            if (val) {
               this.loadTableData()
            }
         }
      }
   },
   methods: {
      onCancel () {
         this.visible = false
         this.$emit('input', this.visible)
      },
      async loadTableData () {
         if (!this.funcName) return 
         // console.log('currentSelectArea',this.selectReigon, this.funcName)
         const regionName = this.selectReigon[0].code
         this.loading = true
         const params = {
            ...this.params,
            parentAreaCode: this.adcode,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            isPageList: 1
         }
         if(regionName && this.funcName === 'getAreaRank') {
            params['regionName'] = regionName
         }
         const { code, data, msg } = await api.chartsManage[this.funcName](params);
         if (code === '00000') {
            this.tableData = data.list
            this.total = data.pageInfo.total
         } else {
            this.$message.error(msg)
         }
         this.loading = false
      }
   }
}
</script>

<style>

</style>