<template>
  <div class="wrap" ref='gmvBarLineChartRef'>
    <a-spin :spinning="spinning" size="large">
      <div class="title-wrap"> 
        <div class="left">
          <div class="title">实收时数据看板</div>
          <a-select v-show="bigAreaVisible" size="small" v-model="bigAreaVal" @change="bigAreaSelectChange" :getPopupContainer='getPopupContainer' style="width: 110px;margin:5px 0 5px 10px;">
            <a-select-option v-for="(val) in bigAreaOptions" :key="val.label" :value="val.value">{{val.label}}</a-select-option>
          </a-select>
          
          <a-tree-select
            v-show="treeSelectVisible"
            v-model="regionValue"
            style="width: 120px;margin: 5px"
            :disabled="treeSelectDisabled"
            :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
            :tree-data="regionData"
            placeholder="请选择"
            showSearch
            :getPopupContainer='getPopupContainer'
            treeNodeFilterProp='title'
            size='small'
            @select='regionSelectChange'
            :treeDefaultExpandedKeys='[0]'
            :replaceFields='replaceFields'
            searchPlaceholder='请输入'
            />
            
        </div>
        <div class="mid"></div>
        <div class="right">
          <div class="search">
            <a-radio-group
              size="small"
              :value="radioDefaultValue"
              button-style="solid"
              @change="onChangeRadio"
            >
              <a-radio-button value="day">
                今天
              </a-radio-button>
              <a-radio-button value="week">
                本周
              </a-radio-button>
              <a-radio-button value="month">
                本月
              </a-radio-button>
              <a-radio-button value="year">
                今年
              </a-radio-button>
            </a-radio-group>
            &nbsp;
            <a-range-picker
              size="small"
              style="width:200px;"
              :getCalendarContainer='getPopupContainer'
              :value="rangeValue"
              @change="onChangeRangePick"
            />
         </div>
        </div>
      </div>
      
      
      <div class="gmv-bar-line-chart" id="gmvBarLineChart"></div>
    </a-spin>
  </div>
</template>

<script>
import api from '@/api';
import _mixin from './_mixin';
import * as echarts from 'echarts';
import moment from 'moment';
export default {
  name: 'gmvBarLineChart',
  mixins:[_mixin],
  props: {
    user_auth: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      spinning: false,
      chart: null,
      chartGmvData: [],
      chartUvData: []
    };
  },
  mounted() {
    this.init();
  },
  updated() {
    // this.init();
  },
  methods: {
    moment,
    async init() {
      this.handleOptionsByAuth(this.user_auth)
      await this.fetchDataFuc()
    },
    // 获取数据
    async fetchDataFuc() {
      try {
        const { startTime, endTime} = this.handleSelectParams()
        if(endTime - startTime > 86400 * 366) {
          this.$message.error('查询时间不能超过一年')
          return
        }
        const code0 = this.currentSelectArea[0].code
        const code1 = this.currentSelectArea[1].code
        const code2 = this.currentSelectArea[2].code
        const code3 = this.currentSelectArea[3].code
        let params = {
          startTime,
          endTime,
          showType: this.dateSelectType,
          areaLevel: '',
          areaCode: ''
        }
        // 如果选择了区
        if(code3 != 0) {
          params.areaLevel = 'district'
          params.areaCode = code3
        }
        // 如果选了市
        if(code2 != 0 && code3 == 0) {
          params.areaLevel = 'city'
          params.areaCode = code2
        // 选了省，不选市 
        }else if (code1 != 0  &&  code2 == 0){
          params.areaLevel = 'province'
          params.areaCode = code1
        // 选了大区，不选省市
        }else if (code0 && code1 == 0 && code2 == 0){
          params.areaLevel = 'region'
          params.areaCode = code0
        // 什么都不选，查全国
        } else if (code0 == 0 && code1 == 0 && code2 == 0 && code3 == 0) {
          params.areaLevel = 'country'
          delete params.areaCode
        }
        this.spinning = true;
        const { code, data, msg } = await api.chartsManage.getGmvList(params);
        if (code === '00000') {
          this.chartGmvData = data?.amountList || [];
        } else {
          this.$message.error(msg)
        }
        const { code: codeUV, data: dataUV , msg: msgUV} = await api.chartsManage.getUV(params);
        if (codeUV === '00000') {
          this.chartUvData = dataUV?.amountList || [];
        }
        else {
          this.$message.error(msgUV)
        }
        this.spinning = false;
        this.initChart();
      } catch (error) {
        console.log(error)
        this.spinning = false;  
      }
    },
    // 渲染图表
    initChart() {
      let chartDom = document.getElementById('gmvBarLineChart');
      if (chartDom) {
        setTimeout(() => {
          if(!this.chartGmvData) {
            return
          }
          this.chart = echarts.init(chartDom, { renderer: 'svg' });
          this.chart.clear()
          let ops = this.getOptions()
          ops.dataZoom = this.dataZoomHandle(this.chartGmvData.length)
          this.chart.setOption(ops);
          window.addEventListener('resize', () => {
            this.chart.clear()
            let ops = this.getOptions()
            ops.dataZoom = this.dataZoomHandle(this.chartGmvData.length)
            this.chart.resize();
            this.chart.setOption(ops);
          });
          this.spinning = false; 
        }, 300);
      }
    },
    // 处理props数据，扁平化
    handleProps(arr) {
      let amount = [];
      let yesterdayAmount = [];
      let lastAvgAmount = [];
      if (!arr) return { amount, yesterdayAmount, lastAvgAmount };
      arr.map(item => {
        if(item.amount == 0){
          amount.push(undefined);
        }else{
          amount.push(item.amount);
        }
        if(item.yesterdayAmount == 0){
          yesterdayAmount.push(undefined);
        }else{
          yesterdayAmount.push(item.yesterdayAmount);
        }
        if(item.lastAvgAmount == 0){
          lastAvgAmount.push(undefined);
        }else{
          lastAvgAmount.push(item.lastAvgAmount);
        }
        // amount.push(item.amount);
        // yesterdayAmount.push(item.yesterdayAmount);
        // lastAvgAmount.push(item.lastAvgAmount);
      });
      return { amount, yesterdayAmount, lastAvgAmount };
    },
    getPopupContainer () {
      return this.$refs.gmvBarLineChartRef
    },
    // 缩放百分比处理 
    dataZoomHandle(num){
      let dataZoom = {
        bottom: '10',
        show: true,
        start: 0,
        end: 100
      }
      if(num <= 31) {
        dataZoom.show = false
        dataZoom.end = 100
      }
      else if (num >31 && num < 100){
        dataZoom.end = 50
      }
      else if (num >= 100 && num < 200) {
        dataZoom.end = 30
      }
      else if(num >= 200 && num < 400) {
        dataZoom.end = 10
      } else {
        dataZoom.end = 10
      }
      return dataZoom
    },
    // 处理charts options
    getOptions() {
      const { 
        amount: amountGmv, 
        yesterdayAmount:yesterdayAmountGmv, 
        lastAvgAmount:lastAvgAmountGmv 
      } = this.handleProps(this.chartGmvData);
      const {
        amount: amountUv,
        yesterdayAmount: yesterdayAmountUv,
        lastAvgAmount: lastAvgAmountUv
      } = this.handleProps(this.chartUvData);
      // 动态生成X轴
      const xLine = this.chartGmvData.map(item => item.time)
      const { showType} = this.handleSelectParams()
      // 选今日的时候，展示多条柱和多条线
      // 选其它的时候，展示一条柱和一条线
      let legendData = []
      let seriesData = []
      if(showType === 'day') {
        legendData = ['今天GMV','昨天GMV','前七天平均值GMV','今天UV','昨天UV','前七天平均值UV']
        seriesData = [
          {
            name: '今天GMV',
            type: 'bar',
            yAxisIndex: 0,
            data: amountGmv
          },
          {
            name: '昨天GMV',
            type: 'bar',
            yAxisIndex: 0,
            data: yesterdayAmountGmv
          },
          {
            name: '前七天平均值GMV',
            type: 'bar',
            yAxisIndex: 0,
            data: lastAvgAmountGmv
          },
          // 折线图
          {
            name: '今天UV',
            type: 'line',
            yAxisIndex: 1,
            data: amountUv
          },
          {
            name: '昨天UV',
            type: 'line',
            yAxisIndex: 1,
            data: yesterdayAmountUv
          },
          {
            name: '前七天平均值UV',
            type: 'line',
            yAxisIndex: 1,
            data: lastAvgAmountUv
          }
        ]
      } else {
        legendData = ['GMV', 'UV']
        seriesData = [
          {
            name: 'GMV',
            type: 'bar',
            yAxisIndex: 0,
            data: amountGmv
          },
          // 折线图
          {
            name: 'UV',
            type: 'line',
            yAxisIndex: 1,
            data: amountUv
          },
        ]
      }
      const option = {
        title: {
          text: '',
          textStyle: {
            color: '#666'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            },
            label: {
              formatter: '{value}'
            },
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%'
        },
        legend: {
          data: legendData
        },
        xAxis: [
          {
            type: 'category',
            data: xLine,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        dataZoom: [
          {
            bottom: '10',
            start: 10,
            end: 20
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'GMV',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: 'UV',
            alignTicks: true,
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: seriesData
      };
      return option;
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 95%;
  padding: 10px;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .title {
    font-size: 20px;
    color: #555;
  }
}
.title-wrap{
  display: flex;
  width: 100%;
  height: 35px;
  .left{
    display: flex;
  }
  .mid{
    flex: 1;
  }
}
.gmv-bar-line-chart {
  width: 100%;
  height: 430px;
  padding: 5px 10px;
}
</style>
