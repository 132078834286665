import moment from 'moment';
// 获取本日、本周、本月,本年的起始时间戳
const day_start = moment()
  .startOf('day')
  .format('X');
const day_end = moment()
  .endOf('day')
  .format('X');
const week_start = moment()
  .startOf('isoWeek')
  .format('X');
const week_end = moment()
  .endOf('isoWeek')
  .format('X');
const month_start = moment()
  .startOf('month')
  .format('X');
const month_end = moment()
  .endOf('month')
  .format('X');
const year_start = moment()
  .startOf('year')
  .format('X');
const year_end = moment()
  .endOf('year')
  .format('X');

export {
  day_start,
  day_end,
  week_start,
  week_end,
  month_start,
  month_end,
  year_start,
  year_end
};
