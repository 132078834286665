export const regionAll = [
   {
     "adcode6": "110000",
     "adcode": "110000000000",
     "nationalAdcode": null,
     "name": "北京市",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "110100",
         "adcode": "110100000000",
         "nationalAdcode": null,
         "name": "北京市",
         "level": 2,
         "parentAdcode": "110000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "sxq"
       }
     ],
     "childrenNum": 1,
     "simpleLetter": "bjs"
   },
   {
     "adcode6": "120000",
     "adcode": "120000000000",
     "nationalAdcode": null,
     "name": "天津市",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "120100",
         "adcode": "120100000000",
         "nationalAdcode": null,
         "name": "天津市",
         "level": 2,
         "parentAdcode": "120000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "sxq"
       }
     ],
     "childrenNum": 1,
     "simpleLetter": "tjs"
   },
   {
     "adcode6": "310000",
     "adcode": "310000000000",
     "nationalAdcode": null,
     "name": "上海市",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "310100",
         "adcode": "310100000000",
         "nationalAdcode": null,
         "name": "上海市",
         "level": 2,
         "parentAdcode": "310000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "sxq"
       }
     ],
     "childrenNum": 1,
     "simpleLetter": "shs"
   },
   {
     "adcode6": "370000",
     "adcode": "370000000000",
     "nationalAdcode": null,
     "name": "山东省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "370400",
         "adcode": "370400000000",
         "nationalAdcode": null,
         "name": "枣庄市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "zzs"
       },
       {
         "adcode6": "371000",
         "adcode": "371000000000",
         "nationalAdcode": null,
         "name": "威海市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "whs"
       },
       {
         "adcode6": "371100",
         "adcode": "371100000000",
         "nationalAdcode": null,
         "name": "日照市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "rzs"
       },
       {
         "adcode6": "371400",
         "adcode": "371400000000",
         "nationalAdcode": null,
         "name": "德州市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "dzs"
       },
       {
         "adcode6": "370100",
         "adcode": "370100000000",
         "nationalAdcode": null,
         "name": "济南市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "jns"
       },
       {
         "adcode6": "370500",
         "adcode": "370500000000",
         "nationalAdcode": null,
         "name": "东营市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "dys"
       },
       {
         "adcode6": "370800",
         "adcode": "370800000000",
         "nationalAdcode": null,
         "name": "济宁市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "jns"
       },
       {
         "adcode6": "371300",
         "adcode": "371300000000",
         "nationalAdcode": null,
         "name": "临沂市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "lys"
       },
       {
         "adcode6": "371500",
         "adcode": "371500000000",
         "nationalAdcode": null,
         "name": "聊城市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "lcs"
       },
       {
         "adcode6": "371700",
         "adcode": "371700000000",
         "nationalAdcode": null,
         "name": "菏泽市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "370200",
         "adcode": "370200000000",
         "nationalAdcode": null,
         "name": "青岛市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "qds"
       },
       {
         "adcode6": "370300",
         "adcode": "370300000000",
         "nationalAdcode": null,
         "name": "淄博市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "zbs"
       },
       {
         "adcode6": "370600",
         "adcode": "370600000000",
         "nationalAdcode": null,
         "name": "烟台市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "yts"
       },
       {
         "adcode6": "370900",
         "adcode": "370900000000",
         "nationalAdcode": null,
         "name": "泰安市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "tas"
       },
       {
         "adcode6": "370700",
         "adcode": "370700000000",
         "nationalAdcode": null,
         "name": "潍坊市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "wfs"
       },
       {
         "adcode6": "371600",
         "adcode": "371600000000",
         "nationalAdcode": null,
         "name": "滨州市",
         "level": 2,
         "parentAdcode": "370000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "bzs"
       }
     ],
     "childrenNum": 16,
     "simpleLetter": "sds"
   },
   {
     "adcode6": "420000",
     "adcode": "420000000000",
     "nationalAdcode": null,
     "name": "湖北省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "420500",
         "adcode": "420500000000",
         "nationalAdcode": null,
         "name": "宜昌市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "421000",
         "adcode": "421000000000",
         "nationalAdcode": null,
         "name": "荆州市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "jzs"
       },
       {
         "adcode6": "422800",
         "adcode": "422800000000",
         "nationalAdcode": null,
         "name": "恩施土家族苗族自治州",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "estjzmzzzz"
       },
       {
         "adcode6": "420800",
         "adcode": "420800000000",
         "nationalAdcode": null,
         "name": "荆门市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "jms"
       },
       {
         "adcode6": "429000",
         "adcode": "429000000000",
         "nationalAdcode": null,
         "name": "省直辖县级行政区划",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "szxxjxzqh"
       },
       {
         "adcode6": "420200",
         "adcode": "420200000000",
         "nationalAdcode": null,
         "name": "黄石市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hss"
       },
       {
         "adcode6": "420700",
         "adcode": "420700000000",
         "nationalAdcode": null,
         "name": "鄂州市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "ezs"
       },
       {
         "adcode6": "421100",
         "adcode": "421100000000",
         "nationalAdcode": null,
         "name": "黄冈市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "hgs"
       },
       {
         "adcode6": "421300",
         "adcode": "421300000000",
         "nationalAdcode": null,
         "name": "随州市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "szs"
       },
       {
         "adcode6": "420100",
         "adcode": "420100000000",
         "nationalAdcode": null,
         "name": "武汉市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "whs"
       },
       {
         "adcode6": "420300",
         "adcode": "420300000000",
         "nationalAdcode": null,
         "name": "十堰市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "sys"
       },
       {
         "adcode6": "420600",
         "adcode": "420600000000",
         "nationalAdcode": null,
         "name": "襄阳市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "xys"
       },
       {
         "adcode6": "420900",
         "adcode": "420900000000",
         "nationalAdcode": null,
         "name": "孝感市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "xgs"
       },
       {
         "adcode6": "421200",
         "adcode": "421200000000",
         "nationalAdcode": null,
         "name": "咸宁市",
         "level": 2,
         "parentAdcode": "420000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "xns"
       }
     ],
     "childrenNum": 14,
     "simpleLetter": "hbs"
   },
   {
     "adcode6": "450000",
     "adcode": "450000000000",
     "nationalAdcode": null,
     "name": "广西壮族自治区",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "450100",
         "adcode": "450100000000",
         "nationalAdcode": null,
         "name": "南宁市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "nns"
       },
       {
         "adcode6": "450300",
         "adcode": "450300000000",
         "nationalAdcode": null,
         "name": "桂林市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 18,
         "simpleLetter": "gls"
       },
       {
         "adcode6": "450700",
         "adcode": "450700000000",
         "nationalAdcode": null,
         "name": "钦州市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "qzs"
       },
       {
         "adcode6": "450900",
         "adcode": "450900000000",
         "nationalAdcode": null,
         "name": "玉林市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "yls"
       },
       {
         "adcode6": "450200",
         "adcode": "450200000000",
         "nationalAdcode": null,
         "name": "柳州市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "lzs"
       },
       {
         "adcode6": "450500",
         "adcode": "450500000000",
         "nationalAdcode": null,
         "name": "北海市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "bhs"
       },
       {
         "adcode6": "450600",
         "adcode": "450600000000",
         "nationalAdcode": null,
         "name": "防城港市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "fcgs"
       },
       {
         "adcode6": "451000",
         "adcode": "451000000000",
         "nationalAdcode": null,
         "name": "百色市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "bss"
       },
       {
         "adcode6": "451200",
         "adcode": "451200000000",
         "nationalAdcode": null,
         "name": "河池市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "hcs"
       },
       {
         "adcode6": "450400",
         "adcode": "450400000000",
         "nationalAdcode": null,
         "name": "梧州市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "wzs"
       },
       {
         "adcode6": "450800",
         "adcode": "450800000000",
         "nationalAdcode": null,
         "name": "贵港市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "ggs"
       },
       {
         "adcode6": "451100",
         "adcode": "451100000000",
         "nationalAdcode": null,
         "name": "贺州市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "451300",
         "adcode": "451300000000",
         "nationalAdcode": null,
         "name": "来宾市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "lbs"
       },
       {
         "adcode6": "451400",
         "adcode": "451400000000",
         "nationalAdcode": null,
         "name": "崇左市",
         "level": 2,
         "parentAdcode": "450000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "czs"
       }
     ],
     "childrenNum": 14,
     "simpleLetter": "gxzzzzq"
   },
   {
     "adcode6": "530000",
     "adcode": "530000000000",
     "nationalAdcode": null,
     "name": "云南省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "530500",
         "adcode": "530500000000",
         "nationalAdcode": null,
         "name": "保山市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "bss"
       },
       {
         "adcode6": "530600",
         "adcode": "530600000000",
         "nationalAdcode": null,
         "name": "昭通市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "zts"
       },
       {
         "adcode6": "530700",
         "adcode": "530700000000",
         "nationalAdcode": null,
         "name": "丽江市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "ljs"
       },
       {
         "adcode6": "530900",
         "adcode": "530900000000",
         "nationalAdcode": null,
         "name": "临沧市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "lcs"
       },
       {
         "adcode6": "532600",
         "adcode": "532600000000",
         "nationalAdcode": null,
         "name": "文山壮族苗族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "wszzmzzzz"
       },
       {
         "adcode6": "530400",
         "adcode": "530400000000",
         "nationalAdcode": null,
         "name": "玉溪市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "yxs"
       },
       {
         "adcode6": "530800",
         "adcode": "530800000000",
         "nationalAdcode": null,
         "name": "普洱市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "pes"
       },
       {
         "adcode6": "532900",
         "adcode": "532900000000",
         "nationalAdcode": null,
         "name": "大理白族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "dlbzzzz"
       },
       {
         "adcode6": "530300",
         "adcode": "530300000000",
         "nationalAdcode": null,
         "name": "曲靖市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "qjs"
       },
       {
         "adcode6": "532300",
         "adcode": "532300000000",
         "nationalAdcode": null,
         "name": "楚雄彝族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "cxyzzzz"
       },
       {
         "adcode6": "532500",
         "adcode": "532500000000",
         "nationalAdcode": null,
         "name": "红河哈尼族彝族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "hhhnzyzzzz"
       },
       {
         "adcode6": "533100",
         "adcode": "533100000000",
         "nationalAdcode": null,
         "name": "德宏傣族景颇族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "dhdzjpzzzz"
       },
       {
         "adcode6": "530100",
         "adcode": "530100000000",
         "nationalAdcode": null,
         "name": "昆明市",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "kms"
       },
       {
         "adcode6": "532800",
         "adcode": "532800000000",
         "nationalAdcode": null,
         "name": "西双版纳傣族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "xsbndzzzz"
       },
       {
         "adcode6": "533300",
         "adcode": "533300000000",
         "nationalAdcode": null,
         "name": "怒江傈僳族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "njlszzzz"
       },
       {
         "adcode6": "533400",
         "adcode": "533400000000",
         "nationalAdcode": null,
         "name": "迪庆藏族自治州",
         "level": 2,
         "parentAdcode": "530000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "dqzzzzz"
       }
     ],
     "childrenNum": 16,
     "simpleLetter": "yns"
   },
   {
     "adcode6": "630000",
     "adcode": "630000000000",
     "nationalAdcode": null,
     "name": "青海省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "630100",
         "adcode": "630100000000",
         "nationalAdcode": null,
         "name": "西宁市",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "xns"
       },
       {
         "adcode6": "632700",
         "adcode": "632700000000",
         "nationalAdcode": null,
         "name": "玉树藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "yszzzzz"
       },
       {
         "adcode6": "630200",
         "adcode": "630200000000",
         "nationalAdcode": null,
         "name": "海东市",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "hds"
       },
       {
         "adcode6": "632300",
         "adcode": "632300000000",
         "nationalAdcode": null,
         "name": "黄南藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "hnzzzzz"
       },
       {
         "adcode6": "632500",
         "adcode": "632500000000",
         "nationalAdcode": null,
         "name": "海南藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "hnzzzzz"
       },
       {
         "adcode6": "632600",
         "adcode": "632600000000",
         "nationalAdcode": null,
         "name": "果洛藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "glzzzzz"
       },
       {
         "adcode6": "632800",
         "adcode": "632800000000",
         "nationalAdcode": null,
         "name": "海西蒙古族藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hxmgzzzzzz"
       },
       {
         "adcode6": "632200",
         "adcode": "632200000000",
         "nationalAdcode": null,
         "name": "海北藏族自治州",
         "level": 2,
         "parentAdcode": "630000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "hbzzzzz"
       }
     ],
     "childrenNum": 8,
     "simpleLetter": "qhs"
   },
   {
     "adcode6": "130000",
     "adcode": "130000000000",
     "nationalAdcode": null,
     "name": "河北省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "130300",
         "adcode": "130300000000",
         "nationalAdcode": null,
         "name": "秦皇岛市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "qhds"
       },
       {
         "adcode6": "130800",
         "adcode": "130800000000",
         "nationalAdcode": null,
         "name": "承德市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "cds"
       },
       {
         "adcode6": "130700",
         "adcode": "130700000000",
         "nationalAdcode": null,
         "name": "张家口市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 20,
         "simpleLetter": "zjks"
       },
       {
         "adcode6": "130900",
         "adcode": "130900000000",
         "nationalAdcode": null,
         "name": "沧州市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 20,
         "simpleLetter": "czs"
       },
       {
         "adcode6": "130200",
         "adcode": "130200000000",
         "nationalAdcode": null,
         "name": "唐山市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 19,
         "simpleLetter": "tss"
       },
       {
         "adcode6": "130600",
         "adcode": "130600000000",
         "nationalAdcode": null,
         "name": "保定市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 27,
         "simpleLetter": "bds"
       },
       {
         "adcode6": "131000",
         "adcode": "131000000000",
         "nationalAdcode": null,
         "name": "廊坊市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "lfs"
       },
       {
         "adcode6": "131100",
         "adcode": "131100000000",
         "nationalAdcode": null,
         "name": "衡水市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "hss"
       },
       {
         "adcode6": "130100",
         "adcode": "130100000000",
         "nationalAdcode": null,
         "name": "石家庄市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 25,
         "simpleLetter": "sjzs"
       },
       {
         "adcode6": "130400",
         "adcode": "130400000000",
         "nationalAdcode": null,
         "name": "邯郸市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 21,
         "simpleLetter": "hds"
       },
       {
         "adcode6": "130500",
         "adcode": "130500000000",
         "nationalAdcode": null,
         "name": "邢台市",
         "level": 2,
         "parentAdcode": "130000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 20,
         "simpleLetter": "xts"
       }
     ],
     "childrenNum": 11,
     "simpleLetter": "hbs"
   },
   {
     "adcode6": "210000",
     "adcode": "210000000000",
     "nationalAdcode": null,
     "name": "辽宁省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "210200",
         "adcode": "210200000000",
         "nationalAdcode": null,
         "name": "大连市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "dls"
       },
       {
         "adcode6": "210600",
         "adcode": "210600000000",
         "nationalAdcode": null,
         "name": "丹东市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "dds"
       },
       {
         "adcode6": "210900",
         "adcode": "210900000000",
         "nationalAdcode": null,
         "name": "阜新市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "fxs"
       },
       {
         "adcode6": "211200",
         "adcode": "211200000000",
         "nationalAdcode": null,
         "name": "铁岭市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "tls"
       },
       {
         "adcode6": "211400",
         "adcode": "211400000000",
         "nationalAdcode": null,
         "name": "葫芦岛市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hlds"
       },
       {
         "adcode6": "210100",
         "adcode": "210100000000",
         "nationalAdcode": null,
         "name": "沈阳市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "sys"
       },
       {
         "adcode6": "210300",
         "adcode": "210300000000",
         "nationalAdcode": null,
         "name": "鞍山市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "ass"
       },
       {
         "adcode6": "210700",
         "adcode": "210700000000",
         "nationalAdcode": null,
         "name": "锦州市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "jzs"
       },
       {
         "adcode6": "211000",
         "adcode": "211000000000",
         "nationalAdcode": null,
         "name": "辽阳市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "lys"
       },
       {
         "adcode6": "210400",
         "adcode": "210400000000",
         "nationalAdcode": null,
         "name": "抚顺市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "fss"
       },
       {
         "adcode6": "210500",
         "adcode": "210500000000",
         "nationalAdcode": null,
         "name": "本溪市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "bxs"
       },
       {
         "adcode6": "210800",
         "adcode": "210800000000",
         "nationalAdcode": null,
         "name": "营口市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "yks"
       },
       {
         "adcode6": "211100",
         "adcode": "211100000000",
         "nationalAdcode": null,
         "name": "盘锦市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "pjs"
       },
       {
         "adcode6": "211300",
         "adcode": "211300000000",
         "nationalAdcode": null,
         "name": "朝阳市",
         "level": 2,
         "parentAdcode": "210000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "cys"
       }
     ],
     "childrenNum": 14,
     "simpleLetter": "lns"
   },
   {
     "adcode6": "220000",
     "adcode": "220000000000",
     "nationalAdcode": null,
     "name": "吉林省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "220100",
         "adcode": "220100000000",
         "nationalAdcode": null,
         "name": "长春市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "zcs"
       },
       {
         "adcode6": "220400",
         "adcode": "220400000000",
         "nationalAdcode": null,
         "name": "辽源市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "lys"
       },
       {
         "adcode6": "220500",
         "adcode": "220500000000",
         "nationalAdcode": null,
         "name": "通化市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "ths"
       },
       {
         "adcode6": "222400",
         "adcode": "222400000000",
         "nationalAdcode": null,
         "name": "延边朝鲜族自治州",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "ybcxzzzz"
       },
       {
         "adcode6": "220200",
         "adcode": "220200000000",
         "nationalAdcode": null,
         "name": "吉林市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "jls"
       },
       {
         "adcode6": "220300",
         "adcode": "220300000000",
         "nationalAdcode": null,
         "name": "四平市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "sps"
       },
       {
         "adcode6": "220800",
         "adcode": "220800000000",
         "nationalAdcode": null,
         "name": "白城市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "bcs"
       },
       {
         "adcode6": "220600",
         "adcode": "220600000000",
         "nationalAdcode": null,
         "name": "白山市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "bss"
       },
       {
         "adcode6": "220700",
         "adcode": "220700000000",
         "nationalAdcode": null,
         "name": "松原市",
         "level": 2,
         "parentAdcode": "220000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "sys"
       }
     ],
     "childrenNum": 9,
     "simpleLetter": "jls"
   },
   {
     "adcode6": "230000",
     "adcode": "230000000000",
     "nationalAdcode": null,
     "name": "黑龙江省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "230100",
         "adcode": "230100000000",
         "nationalAdcode": null,
         "name": "哈尔滨市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 19,
         "simpleLetter": "hebs"
       },
       {
         "adcode6": "230800",
         "adcode": "230800000000",
         "nationalAdcode": null,
         "name": "佳木斯市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "jmss"
       },
       {
         "adcode6": "230900",
         "adcode": "230900000000",
         "nationalAdcode": null,
         "name": "七台河市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "qths"
       },
       {
         "adcode6": "231000",
         "adcode": "231000000000",
         "nationalAdcode": null,
         "name": "牡丹江市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "mdjs"
       },
       {
         "adcode6": "231200",
         "adcode": "231200000000",
         "nationalAdcode": null,
         "name": "绥化市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "shs"
       },
       {
         "adcode6": "230600",
         "adcode": "230600000000",
         "nationalAdcode": null,
         "name": "大庆市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "dqs"
       },
       {
         "adcode6": "232700",
         "adcode": "232700000000",
         "nationalAdcode": null,
         "name": "大兴安岭地区",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "dxaldq"
       },
       {
         "adcode6": "230200",
         "adcode": "230200000000",
         "nationalAdcode": null,
         "name": "齐齐哈尔市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 17,
         "simpleLetter": "qqhes"
       },
       {
         "adcode6": "230400",
         "adcode": "230400000000",
         "nationalAdcode": null,
         "name": "鹤岗市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "hgs"
       },
       {
         "adcode6": "230300",
         "adcode": "230300000000",
         "nationalAdcode": null,
         "name": "鸡西市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "jxs"
       },
       {
         "adcode6": "230500",
         "adcode": "230500000000",
         "nationalAdcode": null,
         "name": "双鸭山市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "syss"
       },
       {
         "adcode6": "230700",
         "adcode": "230700000000",
         "nationalAdcode": null,
         "name": "伊春市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "231100",
         "adcode": "231100000000",
         "nationalAdcode": null,
         "name": "黑河市",
         "level": 2,
         "parentAdcode": "230000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hhs"
       }
     ],
     "childrenNum": 13,
     "simpleLetter": "hljs"
   },
   {
     "adcode6": "500000",
     "adcode": "500000000000",
     "nationalAdcode": null,
     "name": "重庆市",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "500200",
         "adcode": "500200000000",
         "nationalAdcode": null,
         "name": "县",
         "level": 2,
         "parentAdcode": "500000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "x"
       },
       {
         "adcode6": "500100",
         "adcode": "500100000000",
         "nationalAdcode": null,
         "name": "重庆市",
         "level": 2,
         "parentAdcode": "500000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 26,
         "simpleLetter": "sxq"
       }
     ],
     "childrenNum": 2,
     "simpleLetter": "zqs"
   },
   {
     "adcode6": "610000",
     "adcode": "610000000000",
     "nationalAdcode": null,
     "name": "陕西省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "610300",
         "adcode": "610300000000",
         "nationalAdcode": null,
         "name": "宝鸡市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "bjs"
       },
       {
         "adcode6": "610500",
         "adcode": "610500000000",
         "nationalAdcode": null,
         "name": "渭南市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "wns"
       },
       {
         "adcode6": "610700",
         "adcode": "610700000000",
         "nationalAdcode": null,
         "name": "汉中市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "610800",
         "adcode": "610800000000",
         "nationalAdcode": null,
         "name": "榆林市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "yls"
       },
       {
         "adcode6": "610200",
         "adcode": "610200000000",
         "nationalAdcode": null,
         "name": "铜川市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "tcs"
       },
       {
         "adcode6": "610400",
         "adcode": "610400000000",
         "nationalAdcode": null,
         "name": "咸阳市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "xys"
       },
       {
         "adcode6": "610900",
         "adcode": "610900000000",
         "nationalAdcode": null,
         "name": "安康市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "aks"
       },
       {
         "adcode6": "610100",
         "adcode": "610100000000",
         "nationalAdcode": null,
         "name": "西安市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "xas"
       },
       {
         "adcode6": "610600",
         "adcode": "610600000000",
         "nationalAdcode": null,
         "name": "延安市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "yas"
       },
       {
         "adcode6": "611000",
         "adcode": "611000000000",
         "nationalAdcode": null,
         "name": "商洛市",
         "level": 2,
         "parentAdcode": "610000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "sls"
       }
     ],
     "childrenNum": 10,
     "simpleLetter": "sxs"
   },
   {
     "adcode6": "640000",
     "adcode": "640000000000",
     "nationalAdcode": null,
     "name": "宁夏回族自治区",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "640100",
         "adcode": "640100000000",
         "nationalAdcode": null,
         "name": "银川市",
         "level": 2,
         "parentAdcode": "640000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "640500",
         "adcode": "640500000000",
         "nationalAdcode": null,
         "name": "中卫市",
         "level": 2,
         "parentAdcode": "640000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "zws"
       },
       {
         "adcode6": "640300",
         "adcode": "640300000000",
         "nationalAdcode": null,
         "name": "吴忠市",
         "level": 2,
         "parentAdcode": "640000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "wzs"
       },
       {
         "adcode6": "640200",
         "adcode": "640200000000",
         "nationalAdcode": null,
         "name": "石嘴山市",
         "level": 2,
         "parentAdcode": "640000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "szss"
       },
       {
         "adcode6": "640400",
         "adcode": "640400000000",
         "nationalAdcode": null,
         "name": "固原市",
         "level": 2,
         "parentAdcode": "640000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "gys"
       }
     ],
     "childrenNum": 5,
     "simpleLetter": "nxhzzzq"
   },
   {
     "adcode6": "320000",
     "adcode": "320000000000",
     "nationalAdcode": null,
     "name": "江苏省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "320300",
         "adcode": "320300000000",
         "nationalAdcode": null,
         "name": "徐州市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "xzs"
       },
       {
         "adcode6": "320500",
         "adcode": "320500000000",
         "nationalAdcode": null,
         "name": "苏州市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "szs"
       },
       {
         "adcode6": "320800",
         "adcode": "320800000000",
         "nationalAdcode": null,
         "name": "淮安市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "has"
       },
       {
         "adcode6": "320400",
         "adcode": "320400000000",
         "nationalAdcode": null,
         "name": "常州市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "czs"
       },
       {
         "adcode6": "321000",
         "adcode": "321000000000",
         "nationalAdcode": null,
         "name": "扬州市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "yzs"
       },
       {
         "adcode6": "321200",
         "adcode": "321200000000",
         "nationalAdcode": null,
         "name": "泰州市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "tzs"
       },
       {
         "adcode6": "320100",
         "adcode": "320100000000",
         "nationalAdcode": null,
         "name": "南京市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "njs"
       },
       {
         "adcode6": "320600",
         "adcode": "320600000000",
         "nationalAdcode": null,
         "name": "南通市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "nts"
       },
       {
         "adcode6": "320700",
         "adcode": "320700000000",
         "nationalAdcode": null,
         "name": "连云港市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "lygs"
       },
       {
         "adcode6": "320900",
         "adcode": "320900000000",
         "nationalAdcode": null,
         "name": "盐城市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "321100",
         "adcode": "321100000000",
         "nationalAdcode": null,
         "name": "镇江市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "zjs"
       },
       {
         "adcode6": "321300",
         "adcode": "321300000000",
         "nationalAdcode": null,
         "name": "宿迁市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "sqs"
       },
       {
         "adcode6": "320200",
         "adcode": "320200000000",
         "nationalAdcode": null,
         "name": "无锡市",
         "level": 2,
         "parentAdcode": "320000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "wxs"
       }
     ],
     "childrenNum": 13,
     "simpleLetter": "jss"
   },
   {
     "adcode6": "350000",
     "adcode": "350000000000",
     "nationalAdcode": null,
     "name": "福建省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "350700",
         "adcode": "350700000000",
         "nationalAdcode": null,
         "name": "南平市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "nps"
       },
       {
         "adcode6": "350100",
         "adcode": "350100000000",
         "nationalAdcode": null,
         "name": "福州市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "fzs"
       },
       {
         "adcode6": "350200",
         "adcode": "350200000000",
         "nationalAdcode": null,
         "name": "厦门市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "sms"
       },
       {
         "adcode6": "350900",
         "adcode": "350900000000",
         "nationalAdcode": null,
         "name": "宁德市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "nds"
       },
       {
         "adcode6": "350500",
         "adcode": "350500000000",
         "nationalAdcode": null,
         "name": "泉州市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "qzs"
       },
       {
         "adcode6": "350600",
         "adcode": "350600000000",
         "nationalAdcode": null,
         "name": "漳州市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "zzs"
       },
       {
         "adcode6": "350800",
         "adcode": "350800000000",
         "nationalAdcode": null,
         "name": "龙岩市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "lys"
       },
       {
         "adcode6": "350300",
         "adcode": "350300000000",
         "nationalAdcode": null,
         "name": "莆田市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "pts"
       },
       {
         "adcode6": "350400",
         "adcode": "350400000000",
         "nationalAdcode": null,
         "name": "三明市",
         "level": 2,
         "parentAdcode": "350000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "sms"
       }
     ],
     "childrenNum": 9,
     "simpleLetter": "fjs"
   },
   {
     "adcode6": "440000",
     "adcode": "440000000000",
     "nationalAdcode": null,
     "name": "广东省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "440200",
         "adcode": "440200000000",
         "nationalAdcode": null,
         "name": "韶关市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "sgs"
       },
       {
         "adcode6": "441200",
         "adcode": "441200000000",
         "nationalAdcode": null,
         "name": "肇庆市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "zqs"
       },
       {
         "adcode6": "441500",
         "adcode": "441500000000",
         "nationalAdcode": null,
         "name": "汕尾市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "sws"
       },
       {
         "adcode6": "441600",
         "adcode": "441600000000",
         "nationalAdcode": null,
         "name": "河源市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hys"
       },
       {
         "adcode6": "445100",
         "adcode": "445100000000",
         "nationalAdcode": null,
         "name": "潮州市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "czs"
       },
       {
         "adcode6": "445200",
         "adcode": "445200000000",
         "nationalAdcode": null,
         "name": "揭阳市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "jys"
       },
       {
         "adcode6": "440100",
         "adcode": "440100000000",
         "nationalAdcode": null,
         "name": "广州市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "gzs"
       },
       {
         "adcode6": "440500",
         "adcode": "440500000000",
         "nationalAdcode": null,
         "name": "汕头市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "sts"
       },
       {
         "adcode6": "441300",
         "adcode": "441300000000",
         "nationalAdcode": null,
         "name": "惠州市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "441400",
         "adcode": "441400000000",
         "nationalAdcode": null,
         "name": "梅州市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "mzs"
       },
       {
         "adcode6": "445300",
         "adcode": "445300000000",
         "nationalAdcode": null,
         "name": "云浮市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "yfs"
       },
       {
         "adcode6": "440300",
         "adcode": "440300000000",
         "nationalAdcode": null,
         "name": "深圳市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "szs"
       },
       {
         "adcode6": "440600",
         "adcode": "440600000000",
         "nationalAdcode": null,
         "name": "佛山市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "fss"
       },
       {
         "adcode6": "440400",
         "adcode": "440400000000",
         "nationalAdcode": null,
         "name": "珠海市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "zhs"
       },
       {
         "adcode6": "440700",
         "adcode": "440700000000",
         "nationalAdcode": null,
         "name": "江门市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "jms"
       },
       {
         "adcode6": "441700",
         "adcode": "441700000000",
         "nationalAdcode": null,
         "name": "阳江市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "yjs"
       },
       {
         "adcode6": "441900",
         "adcode": "441900000000",
         "nationalAdcode": null,
         "name": "东莞市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 35,
         "simpleLetter": "dgs"
       },
       {
         "adcode6": "440800",
         "adcode": "440800000000",
         "nationalAdcode": null,
         "name": "湛江市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "zjs"
       },
       {
         "adcode6": "440900",
         "adcode": "440900000000",
         "nationalAdcode": null,
         "name": "茂名市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "mms"
       },
       {
         "adcode6": "441800",
         "adcode": "441800000000",
         "nationalAdcode": null,
         "name": "清远市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "qys"
       },
       {
         "adcode6": "442000",
         "adcode": "442000000000",
         "nationalAdcode": null,
         "name": "中山市",
         "level": 2,
         "parentAdcode": "440000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 24,
         "simpleLetter": "zss"
       }
     ],
     "childrenNum": 21,
     "simpleLetter": "gds"
   },
   {
     "adcode6": "460000",
     "adcode": "460000000000",
     "nationalAdcode": null,
     "name": "海南省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "460300",
         "adcode": "460300000000",
         "nationalAdcode": null,
         "name": "三沙市",
         "level": 2,
         "parentAdcode": "460000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "sss"
       },
       {
         "adcode6": "460400",
         "adcode": "460400000000",
         "nationalAdcode": null,
         "name": "儋州市",
         "level": 2,
         "parentAdcode": "460000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 18,
         "simpleLetter": "dzs"
       },
       {
         "adcode6": "469000",
         "adcode": "469000000000",
         "nationalAdcode": null,
         "name": "省直辖县级行政区划",
         "level": 2,
         "parentAdcode": "460000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "szxxjxzqh"
       },
       {
         "adcode6": "460100",
         "adcode": "460100000000",
         "nationalAdcode": null,
         "name": "海口市",
         "level": 2,
         "parentAdcode": "460000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "hks"
       },
       {
         "adcode6": "460200",
         "adcode": "460200000000",
         "nationalAdcode": null,
         "name": "三亚市",
         "level": 2,
         "parentAdcode": "460000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "sys"
       }
     ],
     "childrenNum": 5,
     "simpleLetter": "hns"
   },
   {
     "adcode6": "510000",
     "adcode": "510000000000",
     "nationalAdcode": null,
     "name": "四川省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "511000",
         "adcode": "511000000000",
         "nationalAdcode": null,
         "name": "内江市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "njs"
       },
       {
         "adcode6": "511700",
         "adcode": "511700000000",
         "nationalAdcode": null,
         "name": "达州市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "dzs"
       },
       {
         "adcode6": "510100",
         "adcode": "510100000000",
         "nationalAdcode": null,
         "name": "成都市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 21,
         "simpleLetter": "cds"
       },
       {
         "adcode6": "510600",
         "adcode": "510600000000",
         "nationalAdcode": null,
         "name": "德阳市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "dys"
       },
       {
         "adcode6": "510700",
         "adcode": "510700000000",
         "nationalAdcode": null,
         "name": "绵阳市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "mys"
       },
       {
         "adcode6": "511400",
         "adcode": "511400000000",
         "nationalAdcode": null,
         "name": "眉山市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "mss"
       },
       {
         "adcode6": "511500",
         "adcode": "511500000000",
         "nationalAdcode": null,
         "name": "宜宾市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ybs"
       },
       {
         "adcode6": "513400",
         "adcode": "513400000000",
         "nationalAdcode": null,
         "name": "凉山彝族自治州",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 17,
         "simpleLetter": "lsyzzzz"
       },
       {
         "adcode6": "510400",
         "adcode": "510400000000",
         "nationalAdcode": null,
         "name": "攀枝花市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "pzhs"
       },
       {
         "adcode6": "510500",
         "adcode": "510500000000",
         "nationalAdcode": null,
         "name": "泸州市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "lzs"
       },
       {
         "adcode6": "510800",
         "adcode": "510800000000",
         "nationalAdcode": null,
         "name": "广元市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "gys"
       },
       {
         "adcode6": "510900",
         "adcode": "510900000000",
         "nationalAdcode": null,
         "name": "遂宁市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "sns"
       },
       {
         "adcode6": "511100",
         "adcode": "511100000000",
         "nationalAdcode": null,
         "name": "乐山市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "lss"
       },
       {
         "adcode6": "511600",
         "adcode": "511600000000",
         "nationalAdcode": null,
         "name": "广安市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "gas"
       },
       {
         "adcode6": "512000",
         "adcode": "512000000000",
         "nationalAdcode": null,
         "name": "资阳市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "zys"
       },
       {
         "adcode6": "510300",
         "adcode": "510300000000",
         "nationalAdcode": null,
         "name": "自贡市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "zgs"
       },
       {
         "adcode6": "511300",
         "adcode": "511300000000",
         "nationalAdcode": null,
         "name": "南充市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "ncs"
       },
       {
         "adcode6": "511800",
         "adcode": "511800000000",
         "nationalAdcode": null,
         "name": "雅安市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "yas"
       },
       {
         "adcode6": "511900",
         "adcode": "511900000000",
         "nationalAdcode": null,
         "name": "巴中市",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "bzs"
       },
       {
         "adcode6": "513200",
         "adcode": "513200000000",
         "nationalAdcode": null,
         "name": "阿坝藏族羌族自治州",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "abzzqzzzz"
       },
       {
         "adcode6": "513300",
         "adcode": "513300000000",
         "nationalAdcode": null,
         "name": "甘孜藏族自治州",
         "level": 2,
         "parentAdcode": "510000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 18,
         "simpleLetter": "gzzzzzz"
       }
     ],
     "childrenNum": 21,
     "simpleLetter": "scs"
   },
   {
     "adcode6": "520000",
     "adcode": "520000000000",
     "nationalAdcode": null,
     "name": "贵州省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "520100",
         "adcode": "520100000000",
         "nationalAdcode": null,
         "name": "贵阳市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "gys"
       },
       {
         "adcode6": "520300",
         "adcode": "520300000000",
         "nationalAdcode": null,
         "name": "遵义市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "zys"
       },
       {
         "adcode6": "522300",
         "adcode": "522300000000",
         "nationalAdcode": null,
         "name": "黔西南布依族苗族自治州",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "qxnbyzmzzzz"
       },
       {
         "adcode6": "520200",
         "adcode": "520200000000",
         "nationalAdcode": null,
         "name": "六盘水市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "lpss"
       },
       {
         "adcode6": "520500",
         "adcode": "520500000000",
         "nationalAdcode": null,
         "name": "毕节市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "bjs"
       },
       {
         "adcode6": "520400",
         "adcode": "520400000000",
         "nationalAdcode": null,
         "name": "安顺市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "ass"
       },
       {
         "adcode6": "520600",
         "adcode": "520600000000",
         "nationalAdcode": null,
         "name": "铜仁市",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "trs"
       },
       {
         "adcode6": "522600",
         "adcode": "522600000000",
         "nationalAdcode": null,
         "name": "黔东南苗族侗族自治州",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "qdnmzdzzzz"
       },
       {
         "adcode6": "522700",
         "adcode": "522700000000",
         "nationalAdcode": null,
         "name": "黔南布依族苗族自治州",
         "level": 2,
         "parentAdcode": "520000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "qnbyzmzzzz"
       }
     ],
     "childrenNum": 9,
     "simpleLetter": "gzs"
   },
   {
     "adcode6": "540000",
     "adcode": "540000000000",
     "nationalAdcode": null,
     "name": "西藏自治区",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "540500",
         "adcode": "540500000000",
         "nationalAdcode": null,
         "name": "山南市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "sns"
       },
       {
         "adcode6": "540300",
         "adcode": "540300000000",
         "nationalAdcode": null,
         "name": "昌都市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "cds"
       },
       {
         "adcode6": "540600",
         "adcode": "540600000000",
         "nationalAdcode": null,
         "name": "那曲市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "nqs"
       },
       {
         "adcode6": "542500",
         "adcode": "542500000000",
         "nationalAdcode": null,
         "name": "阿里地区",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "aldq"
       },
       {
         "adcode6": "540100",
         "adcode": "540100000000",
         "nationalAdcode": null,
         "name": "拉萨市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "lss"
       },
       {
         "adcode6": "540200",
         "adcode": "540200000000",
         "nationalAdcode": null,
         "name": "日喀则市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 18,
         "simpleLetter": "rkzs"
       },
       {
         "adcode6": "540400",
         "adcode": "540400000000",
         "nationalAdcode": null,
         "name": "林芝市",
         "level": 2,
         "parentAdcode": "540000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "lzs"
       }
     ],
     "childrenNum": 7,
     "simpleLetter": "xzzzq"
   },
   {
     "adcode6": "620000",
     "adcode": "620000000000",
     "nationalAdcode": null,
     "name": "甘肃省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "620100",
         "adcode": "620100000000",
         "nationalAdcode": null,
         "name": "兰州市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "lzs"
       },
       {
         "adcode6": "620200",
         "adcode": "620200000000",
         "nationalAdcode": null,
         "name": "嘉峪关市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 1,
         "simpleLetter": "jygs"
       },
       {
         "adcode6": "620300",
         "adcode": "620300000000",
         "nationalAdcode": null,
         "name": "金昌市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "jcs"
       },
       {
         "adcode6": "620400",
         "adcode": "620400000000",
         "nationalAdcode": null,
         "name": "白银市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "bys"
       },
       {
         "adcode6": "620500",
         "adcode": "620500000000",
         "nationalAdcode": null,
         "name": "天水市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "tss"
       },
       {
         "adcode6": "620800",
         "adcode": "620800000000",
         "nationalAdcode": null,
         "name": "平凉市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "pls"
       },
       {
         "adcode6": "620600",
         "adcode": "620600000000",
         "nationalAdcode": null,
         "name": "武威市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "wws"
       },
       {
         "adcode6": "621100",
         "adcode": "621100000000",
         "nationalAdcode": null,
         "name": "定西市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "dxs"
       },
       {
         "adcode6": "621200",
         "adcode": "621200000000",
         "nationalAdcode": null,
         "name": "陇南市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "lns"
       },
       {
         "adcode6": "622900",
         "adcode": "622900000000",
         "nationalAdcode": null,
         "name": "临夏回族自治州",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "lxhzzzz"
       },
       {
         "adcode6": "623000",
         "adcode": "623000000000",
         "nationalAdcode": null,
         "name": "甘南藏族自治州",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "gnzzzzz"
       },
       {
         "adcode6": "620700",
         "adcode": "620700000000",
         "nationalAdcode": null,
         "name": "张掖市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "zys"
       },
       {
         "adcode6": "620900",
         "adcode": "620900000000",
         "nationalAdcode": null,
         "name": "酒泉市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "jqs"
       },
       {
         "adcode6": "621000",
         "adcode": "621000000000",
         "nationalAdcode": null,
         "name": "庆阳市",
         "level": 2,
         "parentAdcode": "620000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "qys"
       }
     ],
     "childrenNum": 14,
     "simpleLetter": "gss"
   },
   {
     "adcode6": "140000",
     "adcode": "140000000000",
     "nationalAdcode": null,
     "name": "山西省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "140200",
         "adcode": "140200000000",
         "nationalAdcode": null,
         "name": "大同市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "dts"
       },
       {
         "adcode6": "140700",
         "adcode": "140700000000",
         "nationalAdcode": null,
         "name": "晋中市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "jzs"
       },
       {
         "adcode6": "141100",
         "adcode": "141100000000",
         "nationalAdcode": null,
         "name": "吕梁市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "lls"
       },
       {
         "adcode6": "140300",
         "adcode": "140300000000",
         "nationalAdcode": null,
         "name": "阳泉市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "yqs"
       },
       {
         "adcode6": "140800",
         "adcode": "140800000000",
         "nationalAdcode": null,
         "name": "运城市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "140600",
         "adcode": "140600000000",
         "nationalAdcode": null,
         "name": "朔州市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "szs"
       },
       {
         "adcode6": "141000",
         "adcode": "141000000000",
         "nationalAdcode": null,
         "name": "临汾市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 18,
         "simpleLetter": "lfs"
       },
       {
         "adcode6": "140100",
         "adcode": "140100000000",
         "nationalAdcode": null,
         "name": "太原市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "tys"
       },
       {
         "adcode6": "140400",
         "adcode": "140400000000",
         "nationalAdcode": null,
         "name": "长治市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "zzs"
       },
       {
         "adcode6": "140500",
         "adcode": "140500000000",
         "nationalAdcode": null,
         "name": "晋城市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "jcs"
       },
       {
         "adcode6": "140900",
         "adcode": "140900000000",
         "nationalAdcode": null,
         "name": "忻州市",
         "level": 2,
         "parentAdcode": "140000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "xzs"
       }
     ],
     "childrenNum": 11,
     "simpleLetter": "sxs"
   },
   {
     "adcode6": "150000",
     "adcode": "150000000000",
     "nationalAdcode": null,
     "name": "内蒙古自治区",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "150100",
         "adcode": "150100000000",
         "nationalAdcode": null,
         "name": "呼和浩特市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "hhhts"
       },
       {
         "adcode6": "150600",
         "adcode": "150600000000",
         "nationalAdcode": null,
         "name": "鄂尔多斯市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "eedss"
       },
       {
         "adcode6": "150300",
         "adcode": "150300000000",
         "nationalAdcode": null,
         "name": "乌海市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "whs"
       },
       {
         "adcode6": "150500",
         "adcode": "150500000000",
         "nationalAdcode": null,
         "name": "通辽市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "tls"
       },
       {
         "adcode6": "150700",
         "adcode": "150700000000",
         "nationalAdcode": null,
         "name": "呼伦贝尔市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "hlbes"
       },
       {
         "adcode6": "152500",
         "adcode": "152500000000",
         "nationalAdcode": null,
         "name": "锡林郭勒盟",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "xlglm"
       },
       {
         "adcode6": "150400",
         "adcode": "150400000000",
         "nationalAdcode": null,
         "name": "赤峰市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "cfs"
       },
       {
         "adcode6": "152200",
         "adcode": "152200000000",
         "nationalAdcode": null,
         "name": "兴安盟",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "xam"
       },
       {
         "adcode6": "152900",
         "adcode": "152900000000",
         "nationalAdcode": null,
         "name": "阿拉善盟",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "alsm"
       },
       {
         "adcode6": "150200",
         "adcode": "150200000000",
         "nationalAdcode": null,
         "name": "包头市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "bts"
       },
       {
         "adcode6": "150800",
         "adcode": "150800000000",
         "nationalAdcode": null,
         "name": "巴彦淖尔市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "bynes"
       },
       {
         "adcode6": "150900",
         "adcode": "150900000000",
         "nationalAdcode": null,
         "name": "乌兰察布市",
         "level": 2,
         "parentAdcode": "150000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "wlcbs"
       }
     ],
     "childrenNum": 12,
     "simpleLetter": "nmgzzq"
   },
   {
     "adcode6": "330000",
     "adcode": "330000000000",
     "nationalAdcode": null,
     "name": "浙江省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "330800",
         "adcode": "330800000000",
         "nationalAdcode": null,
         "name": "衢州市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "qzs"
       },
       {
         "adcode6": "331100",
         "adcode": "331100000000",
         "nationalAdcode": null,
         "name": "丽水市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "lss"
       },
       {
         "adcode6": "330200",
         "adcode": "330200000000",
         "nationalAdcode": null,
         "name": "宁波市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "nbs"
       },
       {
         "adcode6": "330600",
         "adcode": "330600000000",
         "nationalAdcode": null,
         "name": "绍兴市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "sxs"
       },
       {
         "adcode6": "330700",
         "adcode": "330700000000",
         "nationalAdcode": null,
         "name": "金华市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "jhs"
       },
       {
         "adcode6": "330500",
         "adcode": "330500000000",
         "nationalAdcode": null,
         "name": "湖州市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "330900",
         "adcode": "330900000000",
         "nationalAdcode": null,
         "name": "舟山市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "zss"
       },
       {
         "adcode6": "330100",
         "adcode": "330100000000",
         "nationalAdcode": null,
         "name": "杭州市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "hzs"
       },
       {
         "adcode6": "330300",
         "adcode": "330300000000",
         "nationalAdcode": null,
         "name": "温州市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "wzs"
       },
       {
         "adcode6": "330400",
         "adcode": "330400000000",
         "nationalAdcode": null,
         "name": "嘉兴市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "jxs"
       },
       {
         "adcode6": "331000",
         "adcode": "331000000000",
         "nationalAdcode": null,
         "name": "台州市",
         "level": 2,
         "parentAdcode": "330000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "tzs"
       }
     ],
     "childrenNum": 11,
     "simpleLetter": "zjs"
   },
   {
     "adcode6": "340000",
     "adcode": "340000000000",
     "nationalAdcode": null,
     "name": "安徽省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "340200",
         "adcode": "340200000000",
         "nationalAdcode": null,
         "name": "芜湖市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "whs"
       },
       {
         "adcode6": "340300",
         "adcode": "340300000000",
         "nationalAdcode": null,
         "name": "蚌埠市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "bbs"
       },
       {
         "adcode6": "341200",
         "adcode": "341200000000",
         "nationalAdcode": null,
         "name": "阜阳市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "fys"
       },
       {
         "adcode6": "341600",
         "adcode": "341600000000",
         "nationalAdcode": null,
         "name": "亳州市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "bzs"
       },
       {
         "adcode6": "340100",
         "adcode": "340100000000",
         "nationalAdcode": null,
         "name": "合肥市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "hfs"
       },
       {
         "adcode6": "340400",
         "adcode": "340400000000",
         "nationalAdcode": null,
         "name": "淮南市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "hns"
       },
       {
         "adcode6": "340700",
         "adcode": "340700000000",
         "nationalAdcode": null,
         "name": "铜陵市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "tls"
       },
       {
         "adcode6": "341000",
         "adcode": "341000000000",
         "nationalAdcode": null,
         "name": "黄山市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "hss"
       },
       {
         "adcode6": "341100",
         "adcode": "341100000000",
         "nationalAdcode": null,
         "name": "滁州市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "czs"
       },
       {
         "adcode6": "341700",
         "adcode": "341700000000",
         "nationalAdcode": null,
         "name": "池州市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "czs"
       },
       {
         "adcode6": "340500",
         "adcode": "340500000000",
         "nationalAdcode": null,
         "name": "马鞍山市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "mass"
       },
       {
         "adcode6": "340600",
         "adcode": "340600000000",
         "nationalAdcode": null,
         "name": "淮北市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "hbs"
       },
       {
         "adcode6": "340800",
         "adcode": "340800000000",
         "nationalAdcode": null,
         "name": "安庆市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "aqs"
       },
       {
         "adcode6": "341300",
         "adcode": "341300000000",
         "nationalAdcode": null,
         "name": "宿州市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "szs"
       },
       {
         "adcode6": "341500",
         "adcode": "341500000000",
         "nationalAdcode": null,
         "name": "六安市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "las"
       },
       {
         "adcode6": "341800",
         "adcode": "341800000000",
         "nationalAdcode": null,
         "name": "宣城市",
         "level": 2,
         "parentAdcode": "340000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "xcs"
       }
     ],
     "childrenNum": 16,
     "simpleLetter": "ahs"
   },
   {
     "adcode6": "360000",
     "adcode": "360000000000",
     "nationalAdcode": null,
     "name": "江西省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "360100",
         "adcode": "360100000000",
         "nationalAdcode": null,
         "name": "南昌市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "ncs"
       },
       {
         "adcode6": "360500",
         "adcode": "360500000000",
         "nationalAdcode": null,
         "name": "新余市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "xys"
       },
       {
         "adcode6": "361000",
         "adcode": "361000000000",
         "nationalAdcode": null,
         "name": "抚州市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "fzs"
       },
       {
         "adcode6": "360400",
         "adcode": "360400000000",
         "nationalAdcode": null,
         "name": "九江市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "jjs"
       },
       {
         "adcode6": "360900",
         "adcode": "360900000000",
         "nationalAdcode": null,
         "name": "宜春市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ycs"
       },
       {
         "adcode6": "360600",
         "adcode": "360600000000",
         "nationalAdcode": null,
         "name": "鹰潭市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "yts"
       },
       {
         "adcode6": "360800",
         "adcode": "360800000000",
         "nationalAdcode": null,
         "name": "吉安市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "jas"
       },
       {
         "adcode6": "361100",
         "adcode": "361100000000",
         "nationalAdcode": null,
         "name": "上饶市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "srs"
       },
       {
         "adcode6": "360200",
         "adcode": "360200000000",
         "nationalAdcode": null,
         "name": "景德镇市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "jdzs"
       },
       {
         "adcode6": "360300",
         "adcode": "360300000000",
         "nationalAdcode": null,
         "name": "萍乡市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "pxs"
       },
       {
         "adcode6": "360700",
         "adcode": "360700000000",
         "nationalAdcode": null,
         "name": "赣州市",
         "level": 2,
         "parentAdcode": "360000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 19,
         "simpleLetter": "gzs"
       }
     ],
     "childrenNum": 11,
     "simpleLetter": "jxs"
   },
   {
     "adcode6": "410000",
     "adcode": "410000000000",
     "nationalAdcode": null,
     "name": "河南省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "410100",
         "adcode": "410100000000",
         "nationalAdcode": null,
         "name": "郑州市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "zzs"
       },
       {
         "adcode6": "410300",
         "adcode": "410300000000",
         "nationalAdcode": null,
         "name": "洛阳市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 17,
         "simpleLetter": "lys"
       },
       {
         "adcode6": "410800",
         "adcode": "410800000000",
         "nationalAdcode": null,
         "name": "焦作市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "jzs"
       },
       {
         "adcode6": "410900",
         "adcode": "410900000000",
         "nationalAdcode": null,
         "name": "濮阳市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "pys"
       },
       {
         "adcode6": "410600",
         "adcode": "410600000000",
         "nationalAdcode": null,
         "name": "鹤壁市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "hbs"
       },
       {
         "adcode6": "411000",
         "adcode": "411000000000",
         "nationalAdcode": null,
         "name": "许昌市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "xcs"
       },
       {
         "adcode6": "411200",
         "adcode": "411200000000",
         "nationalAdcode": null,
         "name": "三门峡市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "smxs"
       },
       {
         "adcode6": "411600",
         "adcode": "411600000000",
         "nationalAdcode": null,
         "name": "周口市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "zks"
       },
       {
         "adcode6": "410400",
         "adcode": "410400000000",
         "nationalAdcode": null,
         "name": "平顶山市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "pdss"
       },
       {
         "adcode6": "410500",
         "adcode": "410500000000",
         "nationalAdcode": null,
         "name": "安阳市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ays"
       },
       {
         "adcode6": "410700",
         "adcode": "410700000000",
         "nationalAdcode": null,
         "name": "新乡市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "xxs"
       },
       {
         "adcode6": "411300",
         "adcode": "411300000000",
         "nationalAdcode": null,
         "name": "南阳市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "nys"
       },
       {
         "adcode6": "411400",
         "adcode": "411400000000",
         "nationalAdcode": null,
         "name": "商丘市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "sqs"
       },
       {
         "adcode6": "411500",
         "adcode": "411500000000",
         "nationalAdcode": null,
         "name": "信阳市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "xys"
       },
       {
         "adcode6": "410200",
         "adcode": "410200000000",
         "nationalAdcode": null,
         "name": "开封市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "kfs"
       },
       {
         "adcode6": "411100",
         "adcode": "411100000000",
         "nationalAdcode": null,
         "name": "漯河市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "lhs"
       },
       {
         "adcode6": "411700",
         "adcode": "411700000000",
         "nationalAdcode": null,
         "name": "驻马店市",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "zmds"
       },
       {
         "adcode6": "419000",
         "adcode": "419000000000",
         "nationalAdcode": null,
         "name": "省直辖县级行政区划",
         "level": 2,
         "parentAdcode": "410000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 1,
         "simpleLetter": "szxxjxzqh"
       }
     ],
     "childrenNum": 18,
     "simpleLetter": "hns"
   },
   {
     "adcode6": "430000",
     "adcode": "430000000000",
     "nationalAdcode": null,
     "name": "湖南省",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "430800",
         "adcode": "430800000000",
         "nationalAdcode": null,
         "name": "张家界市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "zjjs"
       },
       {
         "adcode6": "431300",
         "adcode": "431300000000",
         "nationalAdcode": null,
         "name": "娄底市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 6,
         "simpleLetter": "lds"
       },
       {
         "adcode6": "430200",
         "adcode": "430200000000",
         "nationalAdcode": null,
         "name": "株洲市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "zzs"
       },
       {
         "adcode6": "430500",
         "adcode": "430500000000",
         "nationalAdcode": null,
         "name": "邵阳市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 13,
         "simpleLetter": "sys"
       },
       {
         "adcode6": "431100",
         "adcode": "431100000000",
         "nationalAdcode": null,
         "name": "永州市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 15,
         "simpleLetter": "yzs"
       },
       {
         "adcode6": "431200",
         "adcode": "431200000000",
         "nationalAdcode": null,
         "name": "怀化市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 14,
         "simpleLetter": "hhs"
       },
       {
         "adcode6": "433100",
         "adcode": "433100000000",
         "nationalAdcode": null,
         "name": "湘西土家族苗族自治州",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "xxtjzmzzzz"
       },
       {
         "adcode6": "430100",
         "adcode": "430100000000",
         "nationalAdcode": null,
         "name": "长沙市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "zss"
       },
       {
         "adcode6": "430600",
         "adcode": "430600000000",
         "nationalAdcode": null,
         "name": "岳阳市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "yys"
       },
       {
         "adcode6": "430700",
         "adcode": "430700000000",
         "nationalAdcode": null,
         "name": "常德市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "cds"
       },
       {
         "adcode6": "430900",
         "adcode": "430900000000",
         "nationalAdcode": null,
         "name": "益阳市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "yys"
       },
       {
         "adcode6": "430300",
         "adcode": "430300000000",
         "nationalAdcode": null,
         "name": "湘潭市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "xts"
       },
       {
         "adcode6": "430400",
         "adcode": "430400000000",
         "nationalAdcode": null,
         "name": "衡阳市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 16,
         "simpleLetter": "hys"
       },
       {
         "adcode6": "431000",
         "adcode": "431000000000",
         "nationalAdcode": null,
         "name": "郴州市",
         "level": 2,
         "parentAdcode": "430000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "czs"
       }
     ],
     "childrenNum": 14,
     "simpleLetter": "hns"
   },
   {
     "adcode6": "650000",
     "adcode": "650000000000",
     "nationalAdcode": null,
     "name": "新疆维吾尔自治区",
     "level": 1,
     "parentAdcode": "",
     "version": "GB_20201106",
     "parent": null,
     "children": [
       {
         "adcode6": "650400",
         "adcode": "650400000000",
         "nationalAdcode": null,
         "name": "吐鲁番市",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "tlfs"
       },
       {
         "adcode6": "654000",
         "adcode": "654000000000",
         "nationalAdcode": null,
         "name": "伊犁哈萨克自治州",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 11,
         "simpleLetter": "ylhskzzz"
       },
       {
         "adcode6": "654200",
         "adcode": "654200000000",
         "nationalAdcode": null,
         "name": "塔城地区",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "tcdq"
       },
       {
         "adcode6": "652700",
         "adcode": "652700000000",
         "nationalAdcode": null,
         "name": "博尔塔拉蒙古自治州",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "betlmgzzz"
       },
       {
         "adcode6": "652900",
         "adcode": "652900000000",
         "nationalAdcode": null,
         "name": "阿克苏地区",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "aksdq"
       },
       {
         "adcode6": "653000",
         "adcode": "653000000000",
         "nationalAdcode": null,
         "name": "克孜勒苏柯尔克孜自治州",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 4,
         "simpleLetter": "kzlskekzzzz"
       },
       {
         "adcode6": "653200",
         "adcode": "653200000000",
         "nationalAdcode": null,
         "name": "和田地区",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 8,
         "simpleLetter": "htdq"
       },
       {
         "adcode6": "650100",
         "adcode": "650100000000",
         "nationalAdcode": null,
         "name": "乌鲁木齐市",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 9,
         "simpleLetter": "wlmqs"
       },
       {
         "adcode6": "650200",
         "adcode": "650200000000",
         "nationalAdcode": null,
         "name": "克拉玛依市",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 5,
         "simpleLetter": "klmys"
       },
       {
         "adcode6": "650500",
         "adcode": "650500000000",
         "nationalAdcode": null,
         "name": "哈密市",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 3,
         "simpleLetter": "hms"
       },
       {
         "adcode6": "652300",
         "adcode": "652300000000",
         "nationalAdcode": null,
         "name": "昌吉回族自治州",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "cjhzzzz"
       },
       {
         "adcode6": "652800",
         "adcode": "652800000000",
         "nationalAdcode": null,
         "name": "巴音郭楞蒙古自治州",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "byglmgzzz"
       },
       {
         "adcode6": "653100",
         "adcode": "653100000000",
         "nationalAdcode": null,
         "name": "喀什地区",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 12,
         "simpleLetter": "ksdq"
       },
       {
         "adcode6": "654300",
         "adcode": "654300000000",
         "nationalAdcode": null,
         "name": "阿勒泰地区",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 7,
         "simpleLetter": "altdq"
       },
       {
         "adcode6": "659000",
         "adcode": "659000000000",
         "nationalAdcode": null,
         "name": "自治区直辖县级行政区划",
         "level": 2,
         "parentAdcode": "650000000000",
         "version": "GB_20201106",
         "parent": null,
         "children": null,
         "childrenNum": 10,
         "simpleLetter": "zzqzxxjxzqh"
       }
     ],
     "childrenNum": 15,
     "simpleLetter": "xjwwezzq"
   }
]