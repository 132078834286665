<template>
  <div class="index-charts-wrap">
    <a-spin :spinning="spinning" size="large">
      <template v-if="visible">
        <div class="gmv-header">
          <div v-if="gmv && gmv.todayGmv" class="gmv-h-wrap">
            <div class="box">
              <div class="img"><img src="./images/1.png" /></div>
              <div class="right">
                <div class="title">今日GMV</div>
                <div class="large" v-if="gmv && gmv.todayGmv" style="color: #fca77e">
                  <a-icon type="money-collect" style="" />&nbsp;
                  <AnimatedNumber
                    :value="gmv.todayGmv.amount"
                    :duration="400"
                    :formatValue="formatToPrice"
                  />
                </div>
                <div class="small" v-if="gmv && gmv.todayGmv">
                  <div>
                    较昨日同期
                    <span
                      v-if="gmv.todayGmv.comparePeriod.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.todayGmv.comparePeriod }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.todayGmv.comparePeriod }}
                    </span>
                    &nbsp;&nbsp; 完成率
                    <span
                      v-if="gmv.todayGmv.completePer.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.todayGmv.completePer }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.todayGmv.completePer }}
                    </span>
                  </div>
                  <div style="margin-top: 3px" v-if="gmv.todayGross">
                    毛利
                    <span
                      v-if="gmv.todayGross.amount - 0 < 0"
                      class="green-color"
                    >
                      ￥{{ formatToAmount(gmv.todayGross.amount) }}
                    </span>
                    <span v-else class="red-color">
                      ￥{{ formatToAmount(gmv.todayGross.amount) }}
                    </span>
                    <!-- &nbsp;&nbsp; 较昨日同期
                    <span
                      v-if="gmv.todayGross.comparePeriod.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.todayGross.comparePeriod }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.todayGross.comparePeriod }}
                    </span> -->
                  </div>
                </div>
                <div v-else>暂无数据</div>
              </div>
            </div>
          </div>
          <div v-if="gmv && gmv.monthGmv" class="gmv-h-wrap">
            <div class="box">
              <div class="img"><img src="./images/2.png" /></div>
              <div class="right">
                <div class="title">本月GMV</div>
                <div class="large" v-if="gmv && gmv.monthGmv" style="color: #867efc">
                  <a-icon type="money-collect" style="" />&nbsp;
                  <AnimatedNumber
                    :value="gmv.monthGmv.amount"
                    :duration="700"
                    :formatValue="formatToPrice"
                  />
                </div>
                <div class="small" v-if="gmv && gmv.monthGmv">
                  <div>
                    较上月同期
                    <span
                      v-if="gmv && gmv.monthGmv.comparePeriod.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.monthGmv.comparePeriod }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.monthGmv.comparePeriod }}
                    </span>
                    &nbsp;&nbsp; 完成率
                    <span
                      v-if="gmv && gmv.monthGmv.completePer.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.monthGmv.completePer }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.monthGmv.completePer }}
                    </span>
                  </div>
                  <div style="margin-top: 3px" v-if="gmv.monthGross">
                    毛利
                    <span
                      v-if="gmv && gmv.monthGross.amount - 0 < 0"
                      class="green-color"
                    >
                      ￥{{ formatToAmount(gmv.monthGross.amount) }}
                    </span>
                    <span v-else class="red-color">
                      ￥{{ formatToAmount(gmv.monthGross.amount) }}
                    </span>
                  </div>
                </div>
                <div v-else>暂无数据</div>
              </div>
            </div>
          </div>
          <div v-if="gmv && gmv.yearGmv" class="gmv-h-wrap">
            <div class="box">
              <div class="img"><img src="./images/3.png" /></div>
              <div class="right">
                <div class="title">本年GMV</div>
                <div class="large" v-if="gmv && gmv.yearGmv" style="color: #44d5ca">
                  <a-icon type="money-collect" style="" />&nbsp;
                  <AnimatedNumber
                    :value="gmv.yearGmv.amount"
                    :duration="1000"
                    :formatValue="formatToPrice"
                  />
                </div>
                <div class="small" v-if="gmv && gmv.yearGmv">
                  <div>
                    较上年同期
                    <span
                      v-if="gmv.yearGmv.comparePeriod.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.yearGmv.comparePeriod }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.yearGmv.comparePeriod }}
                    </span>
                    &nbsp;&nbsp; 完成率
                    <span
                      v-if="gmv.yearGmv.completePer.includes('-')"
                      class="green-color"
                    >
                      {{ gmv.yearGmv.completePer }}
                    </span>
                    <span v-else class="red-color">
                      {{ gmv.yearGmv.completePer }}
                    </span>
                  </div>
                  <div style="margin-top: 3px" v-if="gmv && gmv.yearGross">
                    毛利
                    <span
                      v-if="gmv.yearGross.amount - 0 < 0"
                      class="green-color"
                    >
                      ￥{{ formatToAmount(gmv.yearGross.amount) }}
                    </span>
                    <span v-else class="red-color">
                      ￥{{ formatToAmount(gmv.yearGross.amount) }}
                    </span>
                  </div>
                </div>
                <div v-else>暂无数据</div>
              </div>
            </div>
          </div>
        </div>
        <div class="gmv-bar-line-chart">
          <GmvBarLineChart :user_auth="userAuth"/>
        </div>
        <div class="gmv-bar-line-chart">
          <UserBarLineChart :user_auth="userAuth"/>
        </div>
        <div class="rank-charts-wrap">
          <div v-show="ranksVisible.shop" class="rank-box"><ShopRankChart @updateStatus="updateStatus"/></div>
          <div v-show="ranksVisible.area" class="rank-box"><AreaRankChart :user_auth="userAuth"/></div>
          <div v-show="ranksVisible.good" class="rank-box"><GoodRankChart @updateStatus="updateStatus"/></div>
          <div v-show="ranksVisible.gooduv" class="rank-box"><AreaGoodsUvChart @updateStatus="updateStatus"/></div>
        </div>
      </template>
      <!-- <a-skeleton active size="large" v-else /> -->
    </a-spin>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import api from '@/api';
import GmvBarLineChart from './components/gmvBarLineChart.vue';
import UserBarLineChart from './components/userBarLineChart.vue';
import ShopRankChart from './components/shopRankChart.vue';
import AreaRankChart from './components/areaRankChart.vue';
import GoodRankChart from './components/goodRankChart.vue';
import AreaGoodsUvChart from './components/areaGoodsUvChart.vue';
export default {
  name: 'indexCharts',
  components: {
    GmvBarLineChart,
    UserBarLineChart,
    ShopRankChart,
    AreaRankChart,
    GoodRankChart,
    AreaGoodsUvChart,
    AnimatedNumber
  },
  data() {
    return {
      spinning: false,
      visible: false, 
      gmv: null,
      userAuth: null,
      ranksVisible: {
        shop: false,
        area: true,
        good: false,
        gooduv: false
      }
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getData();  
      await this.getUserAuthData();
    },
    // 获取当前用户的权限
    async getUserAuthData() {
      try {
        const { code, data, msg } = await api.chartsManage.getUserAuthInfo();
        if (code === '00000') {
          this.userAuth = data
          this.visible = true
        } else {
          this.visible = false
          this.$message.error(msg)
        }
        this.spinning = false
      } catch (error) {
        this.spinning = false
      }
    },
    async getData() {
      try {
        this.spinning = true
        const { code, data, msg } = await api.chartsManage.getGmv();
        if (code === '00000') {
          this.gmv = data;
        } else {
          this.$message.error(msg)
        }
        this.spinning = false
      } catch (error) {
        this.spinning = false
      }
    },
    updateStatus(data) {
      const k = data.key
      const v = data.status
      this.ranksVisible[k] = v
    },
    formatToPrice(value) {
      return `<span>${Number(value).toFixed(2)}</span>`;
    },
    formatToAmount(value) {
      return Number(value).toFixed(2);
    }
  }
};
</script>

<style lang="less" scoped>
.index-charts-wrap {
  width: 100%;
  padding-bottom: 50px;

  .gmv-bar-line-chart {
    width: 100%;
    height: 500px;
    margin-top: 20px;
    border-radius: 5px;
    // padding: 5px 10px;
    background-color: #fff;
    box-shadow: 1px 1px 13px #ccc;
  }
  .rank-charts-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .rank-box {
      width: 49.5%;
      height: 500px;
      background-color: #fff;
      box-shadow: 1px 1px 13px #ccc;
      border-radius: 5px;
      margin-top: 20px;
    }
  }
}
.gmv-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .gmv-h-wrap {
    width: 32.5%;
    height: 180px;
    display: flex;
    .box {
      position: relative;
      width: 100%;
      color: #222;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 1px 1px 13px #ccc;
      display: flex;
      .img {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 18px;
          margin-bottom: 10px;
          margin-top: 8px;
        }
        .large {
          font-size: 29px;
          margin-left: -4px;
          margin-bottom: 10px;
          font-weight: bold;
          display: flex;
          align-items: center;
          color: #ff4d4f;
        }
        .small {
          font-size: 14px;
          margin-bottom: 10px;
          .red-color {
            color: #ff4d4f;
          }
          .green-color {
            color: #33ca3f;
          }
        }
      }
    }
  }
}
</style>
