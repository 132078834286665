import { day_start, day_end, week_start, week_end, month_start, month_end, year_start, year_end } from './_time'
import { regionAll } from './_region'
export default {
  data() {
    return {
      spinning: false,
      fetchData: [],
      chartInstance: null,
      rangeValue: null, // 时间选择器
      radioDefaultValue: 'day', //按钮组选择
      regionData: [{
        name: '选择(省市)',
        adcode: 0,
        adcode6: 0,
        children: [],
        level: 0
      }], // 省市框的options
      regionDataFull: regionAll, // 全部省市
      regionValue: 0, // 城市选择框
      treeSelectDisabled: false, // 市级账号权限禁用，不然它可以选到省一级
      bigAreaVal: '0', // 大区选择框
      bigAreaVisible: false, // 是否展示大区选择框
      treeSelectVisible: false, // 是否展示城市选择框
      dateSelectType: 'day', //默认选择查询的类型
      moreRankVisable: false, //更多按钮 弹窗
      replaceFields: {
        children:'children', 
        title:'name', 
        key:'adcode', 
        value: 'adcode'
      },
      bigAreaOptions:[],
      // 当前选择情况，查询需要用到
      currentSelectArea: [
        {name: 0 , code: 0}, //总部
        {name: 0 , code: 0}, //省级
        {name: 0 , code: 0}, //市级
        {name: 0 , code: 0} //区级
      ],
      areaToCityCode:{
        'dongbei': ['230000', '220000', '210000', '150000'],
        'huadong': ['320000', '310000', '330000'],
        'huabei': ['110000', '130000', '140000', '120000', '370000'],
        'xinan': ['520000', '510000', '540000', '530000','500000'],
        'huanan': ['350000', '440000', '450000', '460000'],
        'huazhong': ['340000', '410000', '420000', '430000','360000'],
        'xibei': ['620000', '610000', '640000', '650000','630000']
      },
      // 有些接口需要传汉字
      areaToCityName:{
        'dongbei': ['黑龙江省', '吉林省', '辽宁省', '内蒙古自治区'],
        'huadong': ['江苏省', '上海市', '浙江省'],
        'huabei': ['北京市', '河北省', '山西省', '天津市', '山东省'],
        'xinan': ['贵州省', '四川省', '西藏自治区', '云南省','重庆市'],
        'huanan': ['福建省', '广东省', '广西壮族自治区', '海南省'],
        'huazhong': ['安徽省', '河南省', '湖北省', '湖南省','江西省'],
        'xibei': ['甘肃省', '陕西省', '宁夏回族自治区', '新疆维吾尔自治区','青海省']
      }
    }
  },
  methods: {
    // 整理查询条件
    handleSelectParams() {
      let params = {}
      if (this.rangeValue && this.rangeValue.length > 0) {
        const startTime = this.rangeValue[0].startOf('day').format('X') || ''  //获取时间戳 小x是毫秒为单位  大X是秒
        const endTime = this.rangeValue[1].endOf('day').format('X') || ''
        params = { startTime, endTime }
        this.dateSelectType = 'dateRange'
        return params
      }
      switch (this.radioDefaultValue) {
        case 'day':
          this.dateSelectType = 'day'
          params = { startTime: day_start, endTime: day_end }
          break;
        case 'week':
          this.dateSelectType = 'week'
          params = { startTime: week_start, endTime: week_end }
          break;
        case 'month':
          this.dateSelectType = 'month'
          params = { startTime: month_start, endTime: month_end }
          break;
        case 'year':
          this.dateSelectType = 'year'
          params = { startTime: year_start, endTime: year_end }
          break;
      }
      params = {
        ...params,
        showType: this.dateSelectType,
      }
      return params
    },
    // 选择大区触发
    bigAreaSelectChange(val, {data}) {
      this.regionValue = 0
      this.currentSelectArea[0].name = data.key
      this.currentSelectArea[0].code = val
      this.currentSelectArea[1].name = 0
      this.currentSelectArea[1].code = 0
      this.currentSelectArea[2].name = 0
      this.currentSelectArea[2].code = 0
      this.getRegionData(val)
      this.fetchDataFuc()
    },
    // 选择城市触发
    regionSelectChange(val, {dataRef}) {
      let { level, name, adcode, parentAdcode } = dataRef
      if(adcode === 0) {
        this.currentSelectArea[1].name = 0
        this.currentSelectArea[1].code = 0
        this.currentSelectArea[2].name = 0
        this.currentSelectArea[2].code = 0
      }
      if(level == 1) {
        this.currentSelectArea[1].name = name
        this.currentSelectArea[1].code = adcode
        this.currentSelectArea[2].name = 0
        this.currentSelectArea[2].code = 0
      }
      if(level == 2) {
        let provin = this.regionDataFull.filter(item => item.adcode == parentAdcode)
        this.currentSelectArea[1].name = provin[0].name
        this.currentSelectArea[1].code = provin[0].adcode
        this.currentSelectArea[2].name = name
        this.currentSelectArea[2].code = adcode
      }
      this.fetchDataFuc()
    },
    // 更多按钮
    onMoreRankBtn () {
      this.moreRankVisable = true
    },
    // 后端数据扁平化处理,方便渲染 x,y轴
    handleFetchData() {
      let names = []
      let amount = []
      this.fetchData && this.fetchData.length > 0 && this.fetchData.map(item => {
        names.push(item.name)
        amount.push(item.amount)
      })
      return { names, amount }
    },
    // 根据用户权限处理下拉
    handleOptionsByAuth(user, vip){
      const data = user
      // const data = this.testAuth()
      // 区域销售排名不需要限制，给总部账号
      if(vip) {
        data['level'] = vip
      }
      const arr = [
        { value: 0, label: '选择大区'},
        { value: 'dongbei', label: '东北大区'},
        { value: 'huadong', label: '华东大区'},
        { value: 'huabei', label: '华北大区'},
        { value: 'xinan', label: '西南大区'},
        { value: 'huanan', label: '华南大区'},
        { value: 'huazhong', label: '华中大区'},
        { value: 'xibei', label: '西北大区'}
      ]
      // 总部和大区账号，展示大区下拉框
      if(data.level === 'zongbu') {
        this.bigAreaOptions = arr
        this.bigAreaVisible = true
        this.treeSelectVisible = true
        this.treeSelectDisabled = false
        this.bigAreaVal = 0
      }
      else if (data.level === 'region') {
        this.bigAreaOptions = arr.filter(item => data.regionList.includes(item.value))
        this.bigAreaVisible = true
        this.treeSelectVisible = true
        this.treeSelectDisabled = false
        const selOption = this.bigAreaOptions[0]
        const selValue = selOption && selOption.value
        this.bigAreaVal = selValue
        this.currentSelectArea[0].name = selOption.label
        this.currentSelectArea[0].code = selOption.value
        this.getRegionData(selValue)
      }
      else if (data.level === 'province') {
        this.bigAreaVisible = false
        this.treeSelectVisible = true
        this.treeSelectDisabled = false
        this.regionValue = data.provinceCode + '000000'
        let provin = this.regionDataFull.filter(item => item.adcode6 == data.provinceCode)
        this.currentSelectArea[1].name = provin[0].name
        this.currentSelectArea[1].code = provin[0].adcode
        this.currentSelectArea[2].name = 0
        this.currentSelectArea[2].code = 0
        this.currentSelectArea[3].name = 0
        this.currentSelectArea[3].code = 0
        this.regionData = provin
      }
      else if (data.level === 'city') {
        this.bigAreaVisible = false
        this.treeSelectVisible = true
        this.treeSelectDisabled = true
        let p = this.regionDataFull.filter(item => item.adcode6 == data.provinceCode)
        let provin = JSON.parse(JSON.stringify(p[0]))
        let city = provin.children && provin.children.filter(item => item.adcode6 == data.cityCode)
        provin['children'] = city
        this.currentSelectArea[1].name = provin.name
        this.currentSelectArea[1].code = provin.adcode
        this.currentSelectArea[2].name = city[0].name
        this.currentSelectArea[2].code = city[0].adcode
        this.currentSelectArea[3].name = 0
        this.currentSelectArea[3].code = 0
        this.regionData = [provin]
        this.regionValue = city[0].adcode
      }
      else if (data.level === 'district') {
        this.bigAreaVisible = false
        this.treeSelectVisible = false
        this.currentSelectArea[3].name = 0
        this.currentSelectArea[3].code = data.districtCode
      }
    },
    // 模拟身份
    testAuth() {
      const data0 = {
        "level": "zongbu",
        "provinceCode": null,
        "cityCode": null,
        "districtCode": null,
        "regionList": []
      }
      const data1 = {
        "level": "province",
        "provinceCode": "440000",
        "cityCode": null,
        "districtCode": null,
        "regionList": []
      }
      const data2 = {
        "level": "region",
        "provinceCode": '',
        "cityCode": '',
        "districtCode": null,
        "regionList": ['dongbei', 'huabei']
      }
      const data3 = {
        "level": "city",
        "provinceCode": '440000',
        "cityCode": '440200000000',
        "districtCode": null,
        "regionList": []
      }
      return data3
    },
    // 传入大区，得到相应的省市区下拉列表
    getRegionData (area) {
      if(!area) {
        this.regionData = [
          {
            name: '选择(省市)',
            adcode: 0,
            adcode6: 0,
            children: [],
            level: 0
          }
        ]
        return
      }
      const data = JSON.parse(JSON.stringify(this.regionDataFull))
      const filter = this.getOptionsByArea(area, data)
      this.regionData = [
        {
          name: '选择(省市)',
          adcode: 0,
          adcode6: 0,
          children: filter,
          level: 0
        }
      ]
    },
    // 按钮组事件
    onChangeRadio(e) {
      this.radioDefaultValue = e.target.value;
      this.rangeValue = null;
      this.fetchDataFuc()
    },
    // 时间选择器事件
    onChangeRangePick(arr) {
      if (arr && arr.length > 0) {
        this.rangeValue = arr;
        this.radioDefaultValue = '';
      } else {
        this.rangeValue = null;
        this.radioDefaultValue = 'day';
      }
      this.fetchDataFuc()
    },
    // 传入大区获取相应的省，大区可以传入多个,是数组
    // 目前的做法是，默认展示该用户第一个大区数据
    getOptionsByArea (names) {
      const data = this.regionDataFull
      const obj = this.areaToCityCode
      let arr = []
      // 默认就是选一个大区
      if(typeof(names) === 'string' ){
        const area = obj[names] || null
        arr = data.filter(item => {
          if(area && area.includes(item.adcode6)) {
            return item
          }
        })
      } else { //如果角色配了多个大区
        let areas = [] 
        names.map(n => {
          obj[n] && areas.push(...obj[n])
        })
        arr = data.filter(item => {
          if(areas && areas.includes(item.adcode6)) {
            return item
          }
        })
      }
      return arr || []
    },
    // 获取chart渲染配置
    getOptions() {
      let { names, amount } = this.handleFetchData()
      const option = {
        title: {
          show: names.length === 0,
          textStyle: {
            color: '#999',
            fontSize: 18
          },
          text: '暂无数据',
          left: 'center',
          top: 'center'
        },
        color: [this.chartColor],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          top: '5%',
          left: '3%',
          right: '10%',
          bottom: '7%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '¥ {value}'
          }
        },
        yAxis: {
          type: 'category',
          data: names.reverse() || [],
          axisLabel: {
            formatter: function (val) {
              let str = ""
              if(val.length > 13){
                str = val.substring(0,13) + '...'
              }else{
                str = val
              }
              return str
              // 下面的代码是文字超出后换行，可以留着以后使用
              let ret = "";//拼接加\n返回的类目项  
              let maxLength = 10;//每项显示文字个数  
              let valLength = value.length;//X轴类目项的文字个数  
              let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
              if (rowN > 1)//如果类目项的文字大于4,  
              {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串  
                    let start = i * maxLength;//开始截取的位置  
                    let end = start + maxLength;//结束截取的位置  
                      //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串  
                  }
                  return ret;
              }
              else {
                  return value;
              }
            },
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth:20,
            data: amount.reverse() || []
          }
        ]
      };
      return option;
    },
  }
}