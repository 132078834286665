<!-- 更多按钮排名 适用于 店铺，单品，单品UV -->
<template>
  <a-modal v-model="visible" width="750px" @cancel='onCancel' :footer="null" :title="title">
     <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
        :loading="loading"
        :scroll="{x: true}"
        size="default">
     </a-table>
     <a-pagination
        style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
        size="small"
        v-model="pageNum"
        :total="total"
        :showTotal="total => `共 ${total} 条`"
        showSizeChanger
        showLessItems
        showQuickJumper
        :pageSize.sync="pageSize"
        @change="loadTableData"
        @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
     />
   </a-modal>
</template>

<script>
import api from '@/api'
export default {
  name: 'GoodRankTable',
  props: {
     value: {
        type: Boolean,
        default: false,
     },
     params: {
        type: Object,
        default: () => ({})
     },
     funcName: {
        type: String,
        required: true
     }
     
  },
  data () {
     return {
        visible: true,
        title: '',
        pageNum: 1,
        total: 0,
        pageSize: 10,
        columns:[],
        tableData: [],
        loading: false
     }
  },
  
  watch: {
   value: {
      immediate: true,
      handler (val) {
         this.pageNum = 1
         this.pageSize = 10
         this.visible = val
         if (val) {
            this.loadTableData()
         }
      }
   },
  },
  methods: {
     onCancel () {
        this.visible = false
        this.$emit('input', this.visible)
     },
     getColumns () {
      const title = this.title
      let arr = []
      if(title.includes('店铺')) {
        arr = [{
          title: '店铺名称',
          dataIndex: 'name',
          align: 'center',
          ellipsis: true
        },{
          title: '销售额合计',
          dataIndex: 'amount',
          align: 'center',
          ellipsis: true
        }]
      }
      else if(title.includes('区域单品UV')) {
        arr = [{
          title: '商品名称',
          dataIndex: 'name',
          align: 'center',
          ellipsis: true
        },{
          title: '单品UV价值',
          dataIndex: 'amount',
          align: 'center',
          ellipsis: true
        }]
      }
      else {
        arr = [{
          title: '商品名称',
          dataIndex: 'name',
          align: 'center',
          ellipsis: true
        },{
          title: '销售额合计',
          dataIndex: 'amount',
          align: 'center',
          ellipsis: true
        }]
      }
      return arr
     },
     getTitle() {
      const obj = {
         'getShopRank': '店铺销售排名',
         'getGoodRank': '区域单品销售排名',
         'getGoodUvRank': '区域单品UV价值排名',
      }
      this.title = obj[this.funcName]
     },
     async loadTableData () {
        if (!this.funcName) return 
        this.getTitle()
        this.columns = this.getColumns()
        this.loading = true
        const params = {
           ...this.params,
           pageNum: this.pageNum,
           pageSize: this.pageSize,
           isPageList: 1
        }
        const { code, data, msg } = await api.chartsManage[this.funcName](params);
        if (code === '00000') {
           this.tableData = data.list
           this.total = data.pageInfo.total
        } else {
           this.$message.error(msg)
        }
        this.loading = false
     }
  }
}
</script>

<style>

</style>