<template>
  <div class="wrap" ref='areaChartRef'>
    <a-spin :spinning="spinning" size="large">
      <div class="btn-groups">
        <div class="title">区域销售排名</div>
        <div class="search">
            <a-select size="small" v-model="bigAreaVal" @change="bigAreaSelectChange" :getPopupContainer='getPopupContainer' style="width: 110px;margin:5px 5px 5px 0;">
              <a-select-option v-for="(val) in bigAreaOptions" :key="val.value" :value="val.value">{{val.label}}</a-select-option>
            </a-select>
            <a-tree-select
                v-model="regionValue"
                style="width: 120px;margin-bottom: 5px"
                :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                :tree-data="regionData"
                placeholder="请选择"
                showSearch
                :getPopupContainer='getPopupContainer'
                treeNodeFilterProp='title'
                size='small'
                @select='regionSelectChange'
                :treeDefaultExpandedKeys='[0]'
                :replaceFields='replaceFields'
                searchPlaceholder='请输入'
            />
            &nbsp;
            <a-radio-group style="margin-bottom: 5px" size="small" :value="radioDefaultValue" button-style="solid" @change="onChangeRadio">
              <a-radio-button value="day">
                今天
              </a-radio-button>
              <a-radio-button value="week">
                本周
              </a-radio-button>
              <a-radio-button value="month">
                本月
              </a-radio-button>
              <a-radio-button value="year">
                今年
              </a-radio-button>
            </a-radio-group>
            &nbsp;
            <a-range-picker class="picker" size="small" style="width:200px;margin-bottom: 5px" :getCalendarContainer='getPopupContainer' :value="rangeValue" @change="onChangeRangePick" />
            <a class="bar-more-btn" @click="onMoreRankBtn">更多</a>
        </div>
      </div>
      <div class="rank-bar-chart" id="areaRankChart"></div>
    </a-spin>


    <!-- 更多区域销售排行弹窗 -->
    <SaleRankTable v-model="moreRankVisable" :params='handleSelectParams()' :funcName='funcName' :selectReigon="currentSelectArea" :adcode='currentadcode' :level='currentLevel' />
  </div>
    
</template>

<script>
import * as echarts from 'echarts';
import _mixin from './_mixin';
import api from '@/api';
import SaleRankTable from './saleRankTable.vue'
export default{
  name:'areaRankChart',
  mixins:[_mixin],
  components: {
    SaleRankTable
  },
  props: {
    user_auth: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return{
      // chartColor:'#91cc75',
      chartColor:"#A1D389",
      funcName: 'getAreaRank',
      currentLevel: 0,
      currentadcode: 0,
      selectReigon: [],
      tableLoading: false
    }
  },
  mounted(){
    this.init()
    // this.getRegionData()
  },
  methods:{
    async init() {
      this.handleOptionsByAuth(this.user_auth, 'zongbu')
      await this.fetchDataFuc()
    },
    // 渲染图表
    renderChart() {
      let chartDom = document.getElementById('areaRankChart');
      if (chartDom) {
        setTimeout(() => {
          this.chartInstance = echarts.init(chartDom, { renderer: 'svg' });
          this.chartInstance.setOption(this.getOptions());
          window.addEventListener('resize', ()=>{
            this.chartInstance.resize();
            this.chartInstance.setOption(this.getOptions());
          });
          setTimeout(()=>{
            this.chartInstance.resize();
          })
        }, 100);
      }
    },
    //获取数据 //todo ..
    async fetchDataFuc() {
      const { startTime, endTime} = this.handleSelectParams()
      if( (endTime - startTime)  > 31622400 ) {
        this.$message.error('选择时间范围不能大于一年')
        this.rangeValue = null
        return
      }
      const code0 = this.currentSelectArea[0].code
      const code1 = this.currentSelectArea[1].code
      const code2 = this.currentSelectArea[2].code
      let params = {
        startTime,
        endTime,
        showType: this.dateSelectType,
        areaLevel: '',
        regionName: '',
        parentAreaCode: ''
      }
      // 如果选了市
      if(code2 != 0) {
        this.funcName = 'getDistrictRank'
        params.parentAreaCode = code2
        this.currentadcode = code2
        this.currentLevel = 2
      // 选了省，不选市 
      }else if (code1 != 0  &&  code2 == 0){
        this.funcName = 'getCityRank'
        params.parentAreaCode = code1
        this.currentadcode = code1
        this.currentLevel = 1
      // 选了大区，不选省市
      }else if (code0 && code1 == 0 && code2 == 0){
        this.funcName = 'getAreaRank'
        params.regionName = code0
        this.currentadcode = undefined
        this.currentLevel = 0
        delete params.parentAreaCode
      // 什么都不选，查全国
      } else if (code0 == 0 && code1 == 0 && code2 == 0) {
        this.funcName = 'getAreaRank'
        this.currentadcode = undefined
        this.currentLevel = 0
        delete params.regionName
        delete params.parentAreaCode
      } else {
        
      }
      // if (this.currentadcode) {
      //   params.parentAreaCode = this.currentadcode
      // }
      this.spinning = true
      const { code, data } = await api.chartsManage[this.funcName](params);
      if (code === '00000') {
        const { list } = data;
        this.fetchData = list
        this.renderChart();
      }
      this.spinning = false;
    },
    // 保留一下代码
    async getRegionDataTodoTest () {
      this.spinning = true
      let {code, data} = await api.util.getRegion({
        origin: 1,
        level: 3
      })
      if (code === '00000') {
        this.regionData = [
          {
            name: '全国',
            adcode: 0,
            adcode6: 0,
            children: data,
            level: 0
          }
        ]
      }
      this.spinning = false;
    },
    getPopupContainer () {
      return this.$refs.areaChartRef
    },
    onSelect (adcode, {dataRef}) {
      let { level } = dataRef
      let funcName = {
        0: 'getAreaRank',
        1: 'getCityRank',
        2: 'getDistrictRank'
      }
      if (!funcName[level]) return 
      this.funcName = funcName[level]
      this.currentLevel = level
      this.currentadcode = adcode
      this.fetchDataFuc()
    }
  }
}
</script>

<style lang="less" scoped>
@import url(./_common.less);
</style>