<template>
  <div class="wrap" ref="goodRankChartRef">
    <a-spin :spinning="spinning" size="large">
      <div class="btn-groups">
        <div class="title">区域单品销售排名</div>
        <div class="search">
          <a-radio-group
            size="small"
            :value="radioDefaultValue"
            button-style="solid"
            @change="onChangeRadio"
          >
            <a-radio-button value="day">
              今天
            </a-radio-button>
            <a-radio-button value="week">
              本周
            </a-radio-button>
            <a-radio-button value="month">
              本月
            </a-radio-button>
            <a-radio-button value="year">
              今年
            </a-radio-button>
          </a-radio-group>
          &nbsp;
          <a-range-picker
            size="small"
            style="width:200px;"
            :value="rangeValue"
            :getCalendarContainer='getPopupContainer'
            @change="onChangeRangePick"
          />
          <a class="bar-more-btn" @click="onMoreRankBtn">更多</a>
        </div>
      </div>
      <div class="rank-bar-chart" id="goodRankChart"></div>
    </a-spin>
    <!-- 更多排行弹窗 -->
    <GoodRankTable v-model="moreRankVisable" :params='handleSelectParams()' :funcName='funcName'/>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import _mixin from './_mixin';
import api from '@/api';
import GoodRankTable from './goodRankTable.vue'
export default {
  name: 'goodRankChart',
  mixins:[_mixin],
  components: {
    GoodRankTable
  },
  data() {
    return {
      // chartColor:"#fac858",//柱状图的主题色
      chartColor:"#A1D389",
      funcName: 'getGoodRank'
    };
  },
  mounted() {
    this.renderChart();
    this.fetchDataFuc()
  },
  methods: {
    // 渲染图表
    renderChart() {
      let chartDom = document.getElementById('goodRankChart');
      if (chartDom) {
        setTimeout(() => {
          this.chartInstance = echarts.init(chartDom, { renderer: 'svg' });
          this.chartInstance.setOption(this.getOptions());
          window.addEventListener('resize', ()=>{
            this.chartInstance.resize();
            this.chartInstance.setOption(this.getOptions());
          });
          setTimeout(()=>{
            this.chartInstance.resize();
          })
        }, 100);
      }
    },
    //获取数据 //todo ..
    async fetchDataFuc() {
      this.spinning = true
      const params = this.handleSelectParams()
      const { code, data } = await api.chartsManage.getGoodRank(params);
      if(code === '26800') {
        this.$emit('updateStatus', {key: 'good', status: false})
      }
      else if (code === '00000') {
        const { list } = data;
        this.$emit('updateStatus', {key: 'good', status: true})
        this.fetchData = list
        this.renderChart();
      }
      else {
        this.$message.error(msg)
      }
      this.spinning = false;

    },
    getPopupContainer () {
      return this.$refs.goodRankChartRef
    },
  }
};
</script>

<style lang="less" scoped>
@import url(./_common.less);
</style>
